import React from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import { BorderBox, FlexBox, Text, Icon, Section, DropBox, Button } from '../../modules/globalStyle';
import { Popover } from 'antd';
import { useStore } from '../../store/index';
import CountUp from 'react-countup';
import { PoolState, PoolStateStatus } from '../../store/lib/PoolState';
import { TokenState } from '../../store/lib/TokenState';
import BigNumber from 'bignumber.js';

export interface Props {
  title: string;
  pools: PoolState[];
}

export const PoolList = observer((props: Props) => {
  const { lang, god, dev, base } = useStore();

  const store = useLocalStore(() => ({
    deposit(pool: PoolState) {
      if (pool.depositStatus.needConnectWallet) {
        return god.setShowConnecter(true);
      }
      god.deposit(pool);
    },
    approve(token: TokenState, value: BigNumber) {
      god.approve({ token, spender: god.UniswapV2CycloneRouter.address, value: value.toFixed(0) });
    }
  }));
  return (
    <div>
      <BorderBox css={{ padding: '0 0 0.4rem 0', mt: '4rem' }}>
        <FlexBox justify="between">
          <FlexBox justify="start" css={{ width: '32%' }}>
            <Text size="small" color="white" weight="medium">
              {props.title}
            </Text>
            <Popover
              placement="right"
              trigger="hover"
              content={
                <Text size="md" color="white" css={{ width: 200 }}>
                  {lang.t('Pool.tips')}
                </Text>
              }
            >
              <Icon src="/images/home/info_icon.png" css={{ ml: '0.8rem' }} />
            </Popover>
          </FlexBox>
          <FlexBox css={{ flex: 1, textAlign: 'right' }} justify="end" onClick={(e) => god.currentChain.setSort('apy')}>
            <Icon src="/images/home/filter_icon.png" css={{ mr: '0.4rem', width: '0.6rem' }} />
            <Text size="small" color="white" weight="medium" align="right">
              {lang.t('total.apy')} / {lang.t('daily.apr')}
            </Text>
          </FlexBox>
          <FlexBox justify="end" css={{ width: 'max-content', '@md': { width: '40%' } }} onClick={(e) => god.currentChain.setSort('tvl')}>
            <FlexBox css={{ display: 'none', '@md': { display: 'flex' } }}>
              <Icon src="/images/home/filter_icon.png" css={{ mr: '0.4rem', width: '0.6rem' }} />
              <Text size="small" color="white" weight="medium">
                {lang.t('tvl')}
              </Text>
            </FlexBox>
            <Section css={{ width: '1rem', ml: '1rem', '@md': { width: '1.375rem', ml: '1.5rem' } }} />
          </FlexBox>
        </FlexBox>
      </BorderBox>
      {props.pools.map((item, index) => {
        return (
          <article key={item.set}>
            <BorderBox onClick={() => item.setShow(!item.isShow)} css={{ cursor: 'pointer', py: '1rem' }}>
              <FlexBox justify="between">
                <FlexBox css={{ width: 'max-content', cursor: 'pointer', '@md': { width: '32%' } }}>
                  <Icon src={item.icon} css={{ width: '1.25rem', borderRadius: '20px', mr: '0.8rem', '@md': { width: '2.5rem' } }} />
                  <Text size="small" color="primary">
                    {item.set}
                  </Text>
                </FlexBox>
                <Text size="small" color="white" align="right" css={{ flex: 1, textAlign: 'right', cursor: 'pointer' }}>
                  {item.apy}%/ {item.dailyApy}%
                </Text>
                <FlexBox justify="end" css={{ width: 'max-content', cursor: 'pointer', '@md': { width: '40%' } }}>
                  <Text size="small" color="white" align="right" css={{ display: 'none', '@md': { display: 'block' } }}>
                    {item.tvl ? (
                      <CountUp
                        end={Number(item.tvl)}
                        duration={0.5}
                        decimals={2}
                        preserveValue
                        formattingFn={(e) => `$${e.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                      />
                    ) : (
                      '...'
                    )}
                  </Text>
                  <Icon
                    css={{ width: '1rem', ml: '1rem', '@md': { width: '1.375rem', ml: '1.5rem' } }}
                    src={`/images/home/${item.isShow ? 'dropup' : 'dropdown'}.png`}
                  ></Icon>
                </FlexBox>
              </FlexBox>
            </BorderBox>

            {item.isShow && (
              <BorderBox css={{ py: 0 }}>
                <DropBox color="primary">
                  <FlexBox justify="between" responsive="rc">
                    <Section responsive="base" css={{ mb: '2rem', '@md': { mb: 0 } }}>
                      {item.xrcStatus.required && (
                        <BorderBox css={{ padding: '0 0 1rem 0' }}>
                          <FlexBox css={{ mb: '0.4rem' }}>
                            {god.currentNetwork.account && (
                              <Text size="small" color="light" responsive="md">
                                {lang.t('balance')}: {item.XRCToken.balance.format} {item.XRCToken.symbol.toUpperCase()}
                              </Text>
                            )}
                            {!item.xrcStatus.enough && god.currentNetwork.account && (
                              <Popover
                                placement="top"
                                trigger="hover"
                                content={
                                  <Text size="md" color="white" css={{ width: 160 }} align="center">
                                    {lang.t('insufficient.pool', {
                                      amount: item.tokenDenomination.format,
                                      symbol: item.XRCToken.symbol.toUpperCase()
                                    })}
                                  </Text>
                                }
                              >
                                <Icon src="/images/home/warning.png" css={{ ml: '0.5rem' }}></Icon>
                              </Popover>
                            )}
                          </FlexBox>
                          {dev.devUI && (
                            <Text size="small" color="light" responsive="md">
                              {lang.t('allowance')}: {item.XRCToken.allownaceForRouter.format} {item.XRCToken.symbol.toUpperCase()}
                            </Text>
                          )}
                          <Text size="lg" color="white" weight="medium" responsive="lg">
                            {lang.t('asset.denomination')}: {item.tokenDenomination.format} {item.XRCToken.symbol.toUpperCase()}
                            {god.currentNetwork.account && (
                              <Popover
                                placement="top"
                                trigger="hover"
                                content={
                                  <Text size="md" color="white" css={{ width: 300 }} align="center">
                                    {lang.t('deno.warning')}
                                  </Text>
                                }
                              >
                                <Icon src="/images/home/info_icon.png" css={{ ml: '0.5rem' }}></Icon>
                              </Popover>
                            )}
                          </Text>
                        </BorderBox>
                      )}
                      <BorderBox css={{ padding: '1rem 0' }}>
                        <FlexBox css={{ mb: '0.4rem' }}>
                          {god.currentNetwork.account && (
                            <Text size="small" color="light" responsive="md">
                              {lang.t('balance')}: {god.currentChain.Coin.balance.format} {god.currentChain.Coin.symbol.toUpperCase()}
                            </Text>
                          )}

                          {!item.coinStatus.enough && god.currentNetwork.account && (
                            <Popover
                              placement="top"
                              trigger="hover"
                              content={
                                <Text size="md" color="white" css={{ width: 160 }} align="center">
                                  {lang.t('insufficient.pool', {
                                    amount: item.coinDenomination.format,
                                    symbol: god.currentChain.Coin.symbol.toUpperCase()
                                  })}
                                </Text>
                              }
                            >
                              <Icon src="/images/home/warning.png" css={{ ml: '0.5rem' }}></Icon>
                            </Popover>
                          )}
                        </FlexBox>
                        <Text size="lg" color="white" weight="medium" responsive="lg">
                          {lang.t('coin.denomination')}: {item.coinDenomination.format} {god.currentChain.Coin.symbol.toUpperCase()}
                          {god.currentNetwork.account && (
                            <Popover
                              placement="top"
                              trigger="hover"
                              content={
                                <Text size="md" color="white" css={{ width: 300 }} align="center">
                                  {lang.t('deno.warning')}
                                </Text>
                              }
                            >
                              <Icon src="/images/home/info_icon.png" css={{ ml: '0.5rem' }}></Icon>
                            </Popover>
                          )}
                        </Text>
                      </BorderBox>
                      <BorderBox css={{ padding: '1rem 0' }}>
                        <FlexBox css={{ mb: '0.4rem' }}>
                          {god.currentNetwork.account && (
                            <Text size="small" color="light" responsive="md">
                              {lang.t('balance')}: {god.CYCToken.balance.format} {god.CYCToken.symbol.toUpperCase()}
                            </Text>
                          )}
                          {!item.cycStatus.enough && god.currentNetwork.account && (
                            <Popover
                              placement="top"
                              trigger="hover"
                              content={
                                <Text size="md" color="white" css={{ width: 160 }} align="center">
                                  {lang.t('insufficient.pool', {
                                    amount: item.depositCYCAmount.format,
                                    symbol: god.CYCToken.symbol.toUpperCase()
                                  })}
                                </Text>
                              }
                            >
                              <Icon src="/images/home/warning.png" css={{ ml: '0.5rem' }}></Icon>
                            </Popover>
                          )}
                        </FlexBox>
                        {dev.devUI && (
                          <Text size="small" color="light" responsive="md">
                            {lang.t('allowance')}: {god.CYCToken.allownaceForRouter.format} {god.CYCToken.symbol.toUpperCase()}
                          </Text>
                        )}
                        <Text size="lg" color="white" weight="medium" responsive="lg">
                          {lang.t('cyc.denomination')}: {item.depositCYCAmount.format} {god.CYCToken.symbol.toUpperCase()}
                          {(item.version === 2.2 || item.version === 2.3) && (
                            <Popover
                              placement="right"
                              trigger="hover"
                              content={
                                <Text size="md" color="white" css={{ width: 300 }}>
                                  {lang.t('including.anonymity.fee', {
                                    fee: item.anonymityPoolFee.format,
                                    symbol: god.CYCToken.symbol.toUpperCase()
                                  })}
                                </Text>
                              }
                            >
                              <Icon src="/images/home/info_icon.png" css={{ ml: '0.8rem' }} />
                            </Popover>
                          )}
                        </Text>
                      </BorderBox>
                    </Section>

                    <Section responsive="base">
                      {item.cycStatus.showApprove && !item.cycStatus.approved && item.coinStatus.enoughForBuyCyc && (
                        <FlexBox css={{ mb: '2rem' }} onClick={(e) => item.setAllowBuyCYC(!item.allowBuyCYC)}>
                          <Icon src={`/images/home/${item.allowBuyCYC ? 'Checked' : 'Unchecked'}.png`}></Icon>
                          <Text size="small" color="white" css={{ ml: '0.9rem', cursor: 'pointer' }} responsive="md">
                            {lang.t('use.coin.to.buy.cyc', {
                              amount: item.BNBtoBuyCYC.format,
                              symbol: god.currentChain.Coin.symbol
                            })}
                          </Text>
                        </FlexBox>
                      )}
                      <FlexBox justify="between" css={{ mb: '2rem' }}>
                        {item.xrcStatus.showApprove && (
                          <Button
                            size="medium"
                            responsive="lg"
                            disabled={item.xrcStatus.approved || item.XRCToken.metas.isApprovingAllowance}
                            status={item.XRCToken.metas.isApprovingAllowance ? 'loading' : 'normal'}
                            color="approve"
                            onClick={(e) => store.approve(item.XRCToken, item.tokenDenomination.value)}
                          >
                            {`${lang.t('approve')} ${item.XRCToken.symbol.toUpperCase()}`}
                          </Button>
                        )}

                        {item.cycStatus.showApprove && (
                          <Button
                            size="medium"
                            responsive="lg"
                            disabled={item.cycStatus.approved || item.allowBuyCYC.valueOf() || god.CYCToken.metas.isApprovingAllowance}
                            status={god.CYCToken.metas.isApprovingAllowance ? 'loading' : 'normal'}
                            color="approve"
                            onClick={(e) => store.approve(god.CYCToken, item.depositCYCAmount.value.multipliedBy(1.1))}
                          >
                            {`${lang.t('approve')} ${god.CYCToken.symbol.toUpperCase()}`}
                          </Button>
                        )}
                      </FlexBox>

                      <Button
                        size="large"
                        responsive="large"
                        disabled={!item.depositStatus.valid}
                        status={item.status == PoolStateStatus.onDeposit ? 'loading' : 'normal'}
                        color="primary"
                        onClick={(e) => store.deposit(item)}
                      >
                        {item.depositStatus.msg || lang.t('Deposit')}
                      </Button>
                    </Section>
                  </FlexBox>
                </DropBox>

                <DropBox css={{ pt: '1rem', pb: 0, '@md': { py: '1rem' } }}>
                  <FlexBox items="start" justify="between" responsive="rc">
                    <Section responsive="base" css={{ mb: '1rem', '@md': { mb: 0 } }}>
                      <Text size="md" color="white" responsive="sm" css={{ mb: '0.8rem' }}>
                        {lang.t('version')}: {item.version}
                      </Text>
                      <Text size="md" color="white" responsive="sm" css={{ mb: '0.8rem' }}>
                        {lang.t('pool.size')}: {item.numOfShare}
                      </Text>
                      <FlexBox direction="column" items="start">
                        <Text size="md" color="white" responsive="sm" cursor="pointer">
                          {lang.t('pool.contract')}:
                        </Text>
                        <a href={item.poolAddressLink} target="_blank">
                          <Text size="md" color="white" responsive="sm" cursor="pointer">
                            {item.address}
                          </Text>
                        </a>
                      </FlexBox>
                    </Section>
                    <Section responsive="base">
                      <Text size="md" color="white" responsive="sm" css={{ mb: '0.8rem' }}>
                        {lang.t('pool.fee')}:{' '}
                        {item.version === 2.2 || item.version === 2.3
                          ? item.anonymityPoolFee.format + ' ' + `${lang.t('cyc')}`
                          : item.anonymityPoolFee.value.div(100) + '%' + `${lang.t('of.cyc.denomination')}`}
                      </Text>
                      <Text size="md" color="white" responsive="sm" css={{ mb: '0.8rem' }}>
                        {lang.t('relayer.fee')}:{' '}
                        {item.version === 2.2 || item.version === 2.3
                          ? item.relayerFee.format + ' ' + god.currentChain.Coin.symbol.toUpperCase()
                          : item.relayerFee.value.div(100) + '%' + `${lang.t('of.cyc.denomination')}`}
                      </Text>
                    </Section>
                  </FlexBox>
                </DropBox>

                <BorderBox>
                  <FlexBox css={{ px: '0.75rem', '@md': { px: '3.125rem' } }} justify="between" items="center" responsive="rc">
                    <FlexBox css={{ width: '100%', mb: '0.8rem', '@md': { width: '46%', mb: 0 } }} items="center">
                      <Text size="md" color="white" responsive="sm">
                        {lang.t('total.deposits')}：
                      </Text>
                      <Text size="md" color="yellow" responsive="sm">
                        {item.logs.totalCount || '...'}
                      </Text>
                    </FlexBox>
                    <FlexBox css={{ width: '100%', '@md': { width: '46%' } }} justify="between">
                      <FlexBox>
                        <Text size="md" color="white" responsive="sm">
                          {lang.t('latest.deposits')}：
                        </Text>
                        <Text size="md" color="yellow" responsive="sm">
                          {item.LatestDeposits ? base.timeAgo.format(new Date(item.LatestDeposits.timestamp * 1000)) : '...'}
                        </Text>
                      </FlexBox>
                      <Text
                        size="md"
                        color="primary"
                        css={{ textDecoration: 'underline', cursor: 'pointer' }}
                        responsive="sm"
                        onClick={(e) => (item.logs.viewAll = !item.logs.viewAll)}
                      >
                        {item.logs.viewAll ? lang.t('close') : lang.t('view.all')}
                      </Text>
                    </FlexBox>
                  </FlexBox>
                </BorderBox>
                {item.logs.viewAll && (
                  <BorderBox css={{ pb: 0 }}>
                    <FlexBox
                      css={{
                        px: '0.75rem',
                        flexWrap: 'wrap',
                        maxHeight: 160,
                        overflowY: 'auto',
                        '@md': {
                          px: '3.125rem'
                        },
                        '&::-webkit-scrollbar': {
                          width: 2,
                          height: 1
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: '$primary'
                        },
                        '&::-webkit-scrollbar-track': {
                          backgroundColor: '$bg8'
                        }
                      }}
                    >
                      {item.logs.deposits
                        .slice()
                        .reverse()
                        .map((item) => {
                          return (
                            <Text
                              css={{ mb: '0.75rem', width: '50%', '@md': { width: '25%', mb: '1.25rem' }, color: '$primary600 ' }}
                              responsive="md"
                              key={item.leafIndex}
                              size="small"
                              color="white"
                            >
                              {item.leafIndex}. {base.timeAgo.format(new Date(item.timestamp * 1000))}
                            </Text>
                          );
                        })}
                    </FlexBox>
                  </BorderBox>
                )}
              </BorderBox>
            )}
          </article>
        );
      })}
    </div>
  );
});
