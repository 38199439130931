import BigNumber from 'bignumber.js';
import { makeAutoObservable } from 'mobx';
import Config from '../Config';
import God from '../God';
import { rootStore } from './index';

const tempIndex = window.localStorage.getItem('tempIndex');

const transactionsKey = "iotex.transactions"

export class IotexStore {
  constructor() {
    makeAutoObservable(this);
    const transaction = localStorage.getItem(transactionsKey)
    if(transaction){
      this.transactions = JSON.parse(transaction)
    }
  }

  currentTab = tempIndex ? Number(tempIndex) : 0;
  isConnect = false;
  isModalViewShow = false;
  currentSelectedToken = 0;
  currentSelectedPool = 0;
  attachView = '';
  transactions = [];
  records = [];

  currentDenomination = -1;
  currentPoolSize = -1;
  currentBuyBackRate = 0;
  currentMintCycValue = '';
  currentNumberOfShare = '';


  get currentDenominationFormat() {
    return this.currentDenomination >= 0
      ? new BigNumber(this.currentDenomination)
        .dividedBy(new BigNumber(10).pow(18))
        .toFixed(4)
        .toString()
      : '...';
  }

  get currentPoolSizeFormat() {
    return this.currentPoolSize >= 1
      ? new BigNumber(this.currentPoolSize)
        .dividedBy(new BigNumber(10).pow(18))
        .toFixed(4)
        .toString()
      : '...';
  }

  get CurrentToken() {
    return Config.tokens[this.currentSelectedToken];
  }
  get CurrentPool() {
    return this.CurrentToken.amountSteps[this.currentSelectedPool];
  }

  setCurrentTab(index) {
    this.currentTab = index;
  }
  setConnectionStatus(status) {
    this.isConnect = status;
  }
  setCurrentSelectedToken(index) {
    this.currentSelectedToken = index;
  }
  setCurrentSelectedPool(id) {
    this.currentSelectedPool = id;
  }
  setModalViewShow(status) {
    this.isModalViewShow = status;
  }
  setAttachView(attachView) {
    this.attachView = attachView;
  }
  setCurrentDenomination(value) {
    this.currentDenomination = value;
  }
  setCurrentPoolSize(value) {
    this.currentPoolSize = value;
  }
  setCurrentNumberOfShare(value) {
    this.currentNumberOfShare = value;
  }
  updateTransactions(transactions) {
    this.transactions = transactions;
    localStorage.setItem(transactionsKey, JSON.stringify(transactions))

  }
  setRecords(records) {
    this.records = records;
  }

  get makeTableList() {
    let idx = 0;
    let arr = [];
    while (idx < this.records.length) {
      arr.push([this.records[idx], this.records[(idx += 1)]]);
      idx += 1;
    }
    return arr;
  }

  async fetchData() {
    await Promise.all([this.getCurrentAmount(), this.getPoolSize(), this.getNumberofShare()]);
  }

  async getCurrentAmount() {
    this.setCurrentDenomination(-1);
    const set = this.CurrentPool;
    const abi = await God.asyncGetABI(set.abi);
    const currentDenomination = await God.asyncGetDepositDenomination(set.address, abi);
    this.setCurrentDenomination(currentDenomination);
  }

  async getPoolSize() {
    this.setCurrentPoolSize(-1);
    const poolSize = await God.asyncGetIOTXBalance(this.CurrentPool.address);
    this.setCurrentPoolSize(poolSize);
  }
  async getNumberofShare() {
    this.currentNumberOfShare = '...';
    const pool = this.CurrentPool;
    const numberofShares = await God.asyncGetNumOfShares({ address: pool.address, abiUrl: pool.abi });
    this.currentNumberOfShare = numberofShares;
  }

  async loadMintValue() {
    const pool = this.CurrentPool;
    const [_buybackRate, numOfShares, maxNumOfShares, _cycBalance, iotexBalance] = await Promise.all([
      God.asyncGetCYCBuybackRate({ address: pool.address, abiUrl: pool.abi }),
      God.asyncGetNumOfShares({ address: pool.address, abiUrl: pool.abi }),
      God.asyncGetMaxNumOfShares({ address: pool.address, abiUrl: pool.abi }),
      God.asyncGetTotalSupplyOfMimo(),
      God.asyncGetIOTXBalance(Config.mimoExchange.address)
    ]);
    const buybackRate = Number(_buybackRate || 0) / 10000;
    this.currentBuyBackRate = buybackRate;

    const a = God.calculateWithdrawA({ numOfShares, maxNumOfShares });
    const cycBalance = new BigNumber(_cycBalance).multipliedBy(1e18);
    const currentMintIotxValue = new BigNumber(this.currentDenomination).multipliedBy(buybackRate).multipliedBy(a);
    const _cycPrice = new BigNumber(iotexBalance).dividedBy(cycBalance).toNumber();
    const cycPrice = Math.max(_cycPrice, 1000);

    this.currentMintCycValue = currentMintIotxValue
      .dividedBy(cycPrice)
      .dividedBy(1e18)
      .toFixed(4)
      .toString();
  }

  // async loadCycPrice() {
  //   const [_cycBalance, iotexBalance] = await Promise.all([
  //     God.asyncGetTotalSupplyOfMimo(true),
  //     God.asyncGetIOTXBalance(Config.mimoExchange.address)
  //   ]);

  //   console.log("_cycBalance, iotexBalance:", _cycBalance, iotexBalance);

  //   const cycBalance = new BigNumber(_cycBalance).multipliedBy(1e18);
  //   const _cycPrice = new BigNumber(iotexBalance).dividedBy(cycBalance).toNumber();
  //   this.cycForIotex = Math.max(_cycPrice, 1000);
  // }

  init() {
    if (God.isMobile) {
      God.connectIoPay(account => {
        if (account) {
          this.setConnectionStatus(true);
        }
      });
    }
    setTimeout(() => {
      this.preloadFile();
    }, 1000);
  }

  isPreloadFile = false;
  async preloadFile() {
    if (this.isPreloadFile) return;
    this.isPreloadFile = true;
    await Promise.all([God.readVerificationKey(), God.readCircuit(), God.readProvingKey()]);
    this.isPreloadFile = false;
  }

  async getLogs() {
    this.setRecords(null);
    const logs = await God.getLogs(
      Config.tokens[this.currentSelectedToken].amountSteps[this.currentSelectedPool].address
    );
    this.setRecords(logs.reverse());
  }

  logout = () => {
    this.setConnectionStatus(false);
  };
}
