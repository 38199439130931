import { makeAutoObservable } from 'mobx';
import Config from '../Config';
import God from '../God';
import Web3 from 'web3';
import { eventBus } from '../lib/event';
import EthereumConfig from '../EthereumConfig';

const tempIndex = window.localStorage.getItem('tempIndex')

export class EthStore {
  constructor() {
    makeAutoObservable(this);
  }

  currentTab = tempIndex ? Number(tempIndex) : 0;

  isConnect = false;
  isModalViewShow = false;
  currentSelectedToken = 0;
  currentSelectedAmount = 0;
  attachView = '';
  transactions = [];
  records = [];
  numOfRecords = 0;
  web3: Web3;
  chainID = EthereumConfig.defaultChainID;
  account: string = "";

  get CurrentNetwork() {
    return EthereumConfig.tokensOnEthereum[this.chainID];
  }

  get CurrentToken() {
    return this.CurrentNetwork.tokens[this.currentSelectedToken];
  }

  get CurrentSet() {
    return this.CurrentToken[this.currentSelectedAmount];
  }

  setCurrentTab(index) {
    this.currentTab = index;
  }
  setConnectionStatus(status) {
    this.isConnect = status;
  }
  setCurrentSelectedToken(index) {
    this.currentSelectedToken = index;
  }
  setCurrentSelectedAmount(id) {
    this.currentSelectedAmount = id;
  }
  setModalViewShow(status) {
    this.isModalViewShow = status;
  }
  setAttachView(attachView) {
    this.attachView = attachView;
  }
  updateTransactions(transactions) {
    this.transactions = transactions;
  }
  setRecords(count, records) {
    this.numOfRecords = count;
    this.records = records;
  }
  get makeTableList() {
    let idx = 0;
    let arr = [];
    while (idx < this.records.length) {
      arr.push([this.records[idx], this.records[(idx += 1)]]);
      idx += 1;
    }
    return arr;
  }

  init(metamask: boolean) {
    this.connectWeb3(metamask);
    // this.getLogs();
  }

  async connectWeb3(metamask: boolean) {
    if (!God.ethEnabled()) {
      return setTimeout(() => {
        this.connectWeb3(metamask);
      }, 1000);
    }

    if (metamask) {
      //@ts-ignore
      await window.ethereum.enable();
      //@ts-ignore
      God.web3Provider = window.ethereum;
      //@ts-ignore
      this.web3 = new Web3(window.web3.currentProvider);
    } else {
      await God.web3Provider.enable();
      this.web3 = new Web3(God.web3Provider);
    }

    God.isEthereum = true;
    God.web3 = this.web3;
    this.setProviderEventListener();

    eventBus.emit('web3.onConnect', God.web3);
    this.setConnectionStatus(true);

    this.chainID = await this.web3.eth.getChainId();
    God.chainID = this.chainID;
  }

  setProviderEventListener() {
    God.web3Provider.on("accountsChanged", accounts => {
      console.log("accountsChanged", accounts);
      this.account = accounts[0];
    });

    God.web3Provider.on("chainChanged", chainId => {
      console.log("chainChanged", chainId);
      this.chainID = parseInt(chainId, 16);
      God.chainID = this.chainID;
    });

    God.web3Provider.on("connect", () => {
      console.log("connect");
    });

    God.web3Provider.on("disconnect", ({ code, reason }) => {
      console.log("disconnect", code, reason);
    });

    God.web3Provider.on("message", ({ type, data }) => {
      console.log("message", type, data);
    });
  }

  async currentAccount() {
    const accs = await this.web3.eth.getAccounts();
    this.account = accs[0];
    God.defaultAccount = this.account;
    return this.account;
  }

  async getLogs() {
    this.setRecords(0, []);
    const {count, logs} = await God.getLogsOnEthereum(
      EthereumConfig.tokensOnEthereum[this.chainID].tokens[this.currentSelectedToken].amountSteps[this.currentSelectedAmount].address,
      false
    )
    this.setRecords(count, logs.reverse());
  }

  logout = () => {
    this.setConnectionStatus(false);
  };
}
