import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { css } from '../../modules/index';
import { Icon, Section } from '../../modules/globalStyle';
import { useStore } from '../../store';
import { LaunchNav } from '../../components/LaunchNav';
import { Link } from 'react-router-dom';
import { LinksLanguage } from '../../components/LinksLanguage';

export const LaunchPage = observer(() => {
  const { lang, base } = useStore();
  useEffect(() => {}, []);
  return (
    <div className={styles.launchContainer.className}>
      <div className={styles.launch.className}>
        <LaunchNav />
        <div className={styles.launchContent.className}>
          <div className={styles.launchInfos.className}>
            <div className={styles.launchTitle.className}>
              {lang.t('launch.title1')}
              <br />
              {lang.t('launch.title2')}
              <br />
              {lang.t('launch.title3')}
            </div>
            <p className={styles.launchDesc.className}>{lang.t('launch.desc2')}</p>

            {/* <p className={styles.launchDesc.className}>
              {lang.t('frontpage.notice.voting')}&nbsp;
              <a
                style={{ color: 'rgb(56, 220, 213) !important', textDecoration: 'underline' }}
                href="https://snapshot.org/#/realcycloneprotocol.eth/"
              >
                {lang.t('vote.now')}
              </a>
            </p> */}
            <p style={{ color: '#38DCD5', fontWeight: 400, fontSize: '1rem' }}>{lang.t('launch.app.on')}</p>
            <div className={styles.launchBtn.className}>
              <Link to="/eth">
                <Icon src={'/images/home/token_eth.png'} css={{ width: '25px', height: '25px', marginRight: '5px' }} />
                ETH
              </Link>
              <Link to="/bsc">
                <Icon src={'/images/home/token_bnb.png'} css={{ width: '25px', height: '25px', marginRight: '5px' }} /> BSC
              </Link>
              <Link to="/iotx">
                <Icon src={'/images/home/token_iotx.png'} css={{ width: '25px', height: '25px', marginRight: '5px' }} />
                IoTeX
              </Link>
              <Link to="/polygon">
                <Icon src={'/images/home/matic_s.svg'} css={{ width: '25px', height: '25px', marginRight: '5px' }} />
                Polygon
              </Link>
            </div>
            <Section css={{ mt: '2rem', display: 'flex', justifyContent: 'center', '@md': { justifyContent: 'flex-start' } }}>
              <LinksLanguage />
            </Section>
            <p className={styles.tokenPrice.className}>
              {lang.t('home.token.price')}: ${base.cycPrice.toFixed(3)}
            </p>
            <p className={styles.exchangeText.className}>{lang.t('home.exchanges')}</p>
            <div className={styles.exchangeContent.className}>
              <a href="https://uniswap.org/" target="_blank" style={{ display: 'contents' }}>
                <Icon src={'/images/home/uiniswap.png'} css={{ width: '8.625rem' }} />
              </a>
              <a href="https://pancakeswap.finance/" target="_blank">
                <Icon src={'/images/home/pancakeswap_t.png'} css={{ width: '11.5rem', '@md': { width: '13.3rem' } }} />
              </a>
              <a href="https://mimo.exchange/" target="_blank">
                <Icon src={'/images/home/mimo.png'} css={{ width: '5rem', marginTop: '1rem', '@md': { marginTop: 0 } }} />
              </a>
              <a href="https://quickswap.exchange/" target="_blank" style={{ display: 'contents' }}>
                <Icon src={'/images/home/quickswap_t.png'} css={{ width: '11.125rem', marginTop: '1rem', '@md': { marginTop: 0 } }} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const styles = {
  launchContainer: css({
    width: '100vw',
    minHeight: '100vh',
    backgroundColor: 'linear-gradient(91.1deg, #000000 0.79%, #3B180A 98.94%)',
    backgroundImage: 'url("images/mobile_luanch_bg.png")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
    overflowX: 'hidden',
    paddingBottom: '0',
    '@md': {
      height: '100vh',
      backgroundSize: 'cover',
      backgroundPosition: 'top right',
      paddingBottom: '0',
      backgroundColor: 'linear-gradient(91.1deg, #000000 0.79%, #3B180A 98.94%)',
      backgroundImage: 'url("images/luanch_bg.png")'
    },
    '.cyclone-footer': {
      flexDirection: 'row-reverse',
      marginTop: '2rem',
      justifyContent: 'center !important',
      marginBottom: 0,
      paddingBottom: '2rem',
      '@md': {
        justifyContent: 'flex-end !important',
        marginTop: '2rem'
      }
    }
  }),
  launch: css({
    maxWidth: '1120px',
    margin: 'auto',
    padding: '1.5rem 1.2rem 2.125rem 1.2rem',
    boxSizing: 'border-box',
    '@md': {
      height: '100vh',
      padding: '2.125rem 1.2rem 2.125rem 1.2rem'
    }
  }),
  launchContent: css({
    color: '#fff',
    overflow: 'hidden',
    marginTop: '4rem',
    '@md': {
      marginTop: '5rem'
    }
  }),
  launchInfos: css({
    '@md': {
      maxWidth: '750px'
    }
  }),
  launchTitle: css({
    fontFamily: 'Montserrat',
    color: '#ffffff',
    fontSize: '2rem',
    lineHeight: '3rem',
    marginBottom: '2rem',
    textAlign: 'center',
    fontWeight: 600,
    '@md': {
      textAlign: 'left',
      fontSize: '3.25rem',
      lineHeight: '4.5rem',
      marginBottom: '1rem'
    }
  }),
  launchDesc: css({
    color: '#FFFFFF',
    fontSize: '1.125rem',
    marginBottom: '2.5rem',
    fontFamily: 'Montserrat',
    maxWidth: '600px',
    textAlign: 'center',
    a: {
      color: 'rgb(56, 220, 213) !important'
    },
    '@md': {
      textAlign: 'left',
      marginBottom: '2rem'
    },
    '@sm': {
      maxWidth: 'unset'
    },
    fontWeight: 600
  }),
  launchBtn: css({
    height: '120px',
    lineHeight: '50px',
    color: '#0F0604',
    fontSize: '20px',
    fontWeight: 600,
    textAlign: 'center',
    margin: '0 auto 0',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '@sm': {
      flexDirection: 'row',
      height: '52px',
      lineHeight: '52px',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start'
    },
    a: {
      display: 'block',
      margin: '10px 0 0 10px',
      width: '45%',
      height: '52px',
      color: '#0F0604 !important',
      backgroundColor: '#38DCD5',
      '&:hover': {
        color: '#ffff !important',
        backgroundColor: '#24928E'
      },
      '@md': {
        width: '100%'
      }
    },
    'a:first-child': {
      margin: '10px 0 0 0'
    },
    '@md': {
      margin: 'inherit'
    }
  }),
  tokenPrice: css({
    marginTop: '2rem',
    fontSize: '1.5rem',
    fontWeight: 400,
    color: '#38DCD5',
    marginBottom: '0!important'
  }),
  exchangeText: css({
    fontSize: '1.5rem',
    fontWeight: 600,
    marginTop: '0.5rem'
  }),
  exchangeContent: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    '@md': {
      flexWrap: 'no-wrap'
    }
  })
};
