import React, { useEffect, useState } from 'react';
import NavigatorBar from '../../components/NavigatorBar';
import TabBar from '../../components/TabBar';
import God from '../../God';
import ModalView from '../../components/ModalView';
import Loading from '../../components/Loading';
import CheckNoteView from '../../components/CheckNoteView';
import TransactionRecord from '../../components/TransactionRecord';
import TransactionObject from '../../TransactionObject';
import Alert from '../../components/Alert';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { navStyles, mainStyles, globalStyle } from '../../styles/common';
import ReactTooltip from 'react-tooltip';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../store';
import YourIp from '../../components/YourIp';
import ConnectorSelector from '../../components/ConnectorSelector';
import Footer from '../../components/Footer';
import Config from '../../EthereumConfig';
import WithdrawViewForETH from '../../components/WithdrawViewForETH';
import LiquidtyViewForBSC from '../../components/liquidtyViewForBSC';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import { eventBus } from '../../lib/event';
import Web3 from 'web3';
import EthereumConfig from '../../EthereumConfig';
import DepositViewForBSC from '../../components/DepositViewForBSC';
import CountUp from 'react-countup';
import { Chain } from '../../components/Chain';
import { injectedConnector } from '../../lib/web3-react-bsc';

let theWithdrawComponent = null;

export const BscPage = observer(() => {
  const { base, bsc: pageStore, lang, base: baseStore } = useStore();
  const [attachView, setAttachView] = useState(null);
  const { active, chainId, library, account, activate } = useWeb3React();

  // const [biggerContainer, setContainerAsBigger] = useState(false)

  useEffect(() => {
    God.init();
    God.isEthereum = true;
    God.isBSC = true;

    base.init();
    lang.init();
  }, []);

  useEffect(() => {
    console.log({ library });
    const web3 = library
      ? new Web3(library)
      : new Web3(EthereumConfig.tokensOnEthereum[String(EthereumConfig.defaultChainID)].rpcUrl);
    God.web3 = web3;
    God.web3Provider = library;
    pageStore.web3 = web3;
    pageStore.getLogs();

    if (library) {
      setAttachView(null);
      pageStore.setConnectionStatus(true);
      pageStore.setModalViewShow(false);
      eventBus.emit('web3.onConnect', God.web3);

      // Testing...
      // addNewTransaction("0x0b8abfcb9ddc4e301f51813363586520422ad3a6cf98c94b0472bd4cbd7151cb", "123456789012345678", "99999999999999999999999", "0xjakfhjksdhfasd", [], "0x888888888888", true);
    }
  }, [library]);

  // auto connect wallet
  useEffect(() => {
    if ((activate, pageStore.latestEthProvider.value)) {
      if (pageStore.latestEthProvider.value == 'inject') {
        activate(injectedConnector);
      }
    }
  }, [activate, pageStore.latestEthProvider.value]);

  useEffect(() => {
    console.log({ account, active, chainId });
    God.defaultAccount = account;
    pageStore.account = account;
    if (account) {
      pageStore.getBalanceOfCYC();
      pageStore.getTokenAllowance();
    }
    if (chainId) {
      if (EthereumConfig.allowChains.bsc.includes(chainId)) {
        pageStore.chainID = chainId;
        God.chainID = chainId;
      }
    }
  }, [account, active, chainId]);

  useEffect(() => {
    pageStore.apyMap.update();
  }, [baseStore.refetchTimer]);

  const connectIoPay = () => {
    pageStore.setModalViewShow(true);
    setAttachView(
      <ModalView>
        <ConnectorSelector
          text={lang.t('eth.connect')}
          onClickCloseButton={() => {
            pageStore.setModalViewShow(false);
            setAttachView(null);
          }}
        />
      </ModalView>
    );
  };

  const addNewTransaction = (txid, amountXRC, amountCYC, commitment, abi, note, isDeposit, coinDenomination) => {
    let newTx = new TransactionObject(
      new Date().getTime(),
      amountXRC,
      txid,
      pageStore.currentSelectedAmount,
      pageStore.CurrentSet.id,
      commitment,
      abi,
      note,
      isDeposit
    );
    newTx.setAmountCYC(amountCYC);
    newTx.setPoolName(pageStore.CurrentSet.set);
    newTx.setApy(pageStore.apy);
    newTx.setCoin(coinDenomination);

    pageStore.transactions.unshift(newTx);
    pageStore.updateTransactions([...pageStore.transactions]);

    setTimeout(() => {
      newTx.getTransactionOnEthereum(() => {
        pageStore.updateTransactions([...pageStore.transactions]);

        if (newTx.note) {
          setTimeout(() => {
            newTx.getSubsequent(() => {
              pageStore.updateTransactions([...pageStore.transactions]);
            });
          }, 3000);
        }
      });
    }, 3000);
  };

  const updateTransaction = (res, note) => {
    let newTx = pageStore.transactions.find(item => {
      return item.note === note;
    });

    if (!newTx || typeof res == 'object') {
      return;
    }

    newTx.txHash = res;
    newTx.status = 3;
    pageStore.updateTransactions([...pageStore.transactions]);

    setTimeout(() => {
      if (!newTx || !newTx.hasOwnProperty('getTransactionOnEthereum')) {
        return;
      }

      newTx.getTransactionOnEthereum(() => {
        pageStore.updateTransactions([...pageStore.transactions]);

        setTimeout(() => {
          updateAllSubsepents();
        }, 1000);
      });
    }, 3000);
  };

  const updateAllSubsepents = () => {
    for (let i = 0; i < pageStore.transactions.length; i++) {
      const element = pageStore.transactions[i];
      if (element.note) {
        element.getSubsequent(() => {
          pageStore.updateTransactions([...pageStore.transactions]);
        });
      }
    }
  };

  const approveXRC = (contractAddress, amount) => {
    pageStore.setModalViewShow(true);
    setAttachView(
      <ModalView>
        <Loading
          action={async () => {
            const approveAmount = new BigNumber(amount)
              .multipliedBy(1.1)
              .integerValue(BigNumber.ROUND_FLOOR)
              .toFixed();
            const txid = await God.asyncApproveToken(
              pageStore.CurrentSet.XRCToken.address,
              pageStore.CurrentSet.XRCToken.abi,
              pageStore.CurrentSet.UniswapV2CycloneRouter.address,
              approveAmount
            );

            if (txid) {
              pageStore.XRCAllowance = approveAmount;
            }

            pageStore.setModalViewShow(false);
            setAttachView(null);
          }}
          text={lang.t('Loading.text1.eth', { token: pageStore.CurrentSet.XRCToken.symbol })}
          cancelable={true}
          cancel={() => {
            pageStore.setModalViewShow(false);
            setAttachView(null);
          }}
        />
      </ModalView>
    );
  };

  const approveCYC = (contractAddress, amount) => {
    pageStore.setModalViewShow(true);
    setAttachView(
      <ModalView>
        <Loading
          action={async () => {
            const abi = pageStore.CurrentNetwork.CYCToken.abi;
            const approveAmount = new BigNumber(amount)
              .multipliedBy(1.1)
              .integerValue(BigNumber.ROUND_FLOOR)
              .toFixed();

            console.log('Approve CYC:', amount, approveAmount);

            const txid = await God.asyncApproveToken(
              pageStore.CurrentNetwork.CYCToken.address,
              abi,
              pageStore.CurrentSet.UniswapV2CycloneRouter.address,
              approveAmount
            );

            if (txid) {
              pageStore.CYCAllowance = approveAmount;
            }

            pageStore.setModalViewShow(false);
            setAttachView(null);
          }}
          text={lang.t('Loading.text2.eth')}
          cancelable={true}
          cancel={() => {
            pageStore.setModalViewShow(false);
            setAttachView(null);
          }}
        />
      </ModalView>
    );
  };

  const onDeposit = async (contractAddress, amount, cycDenomination, cycBalance, coinDenomination, poolID, buyCYC) => {
    await pageStore.getCurrentBlockNumber();
    const note = God.makeNoteV2(
      // pageStore.CurrentToken.name,
      // amount,
      pageStore.chainID,
      poolID
    );

    const poolname = EthereumConfig.tokensOnEthereum[pageStore.chainID].pools[poolID].set;
    const currency = Config.tokensOnEthereum[pageStore.chainID].coin.symbol;

    // download text
    if (window.navigator.userAgent.indexOf('iPhone') < 0 && note && !God.isMobile) {
      let blob = new Blob([note.note]);
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = `${poolname}-${currency}-${lang.t('note')}.txt`;
      a.click();
      window.URL.revokeObjectURL(url);
    }

    pageStore.setModalViewShow(true);
    setAttachView(
      <ModalView>
        <CheckNoteView
          title={lang.t('YourNote')}
          note={note.note}
          onClickCloseButton={() => {
            pageStore.setModalViewShow(false);
            setAttachView(null);
          }}
          onSend={() => {
            setAttachView(
              <ModalView>
                <Loading
                  action={async () => {
                    if (cycDenomination.comparedTo(0) > 0) {
                      await God.asyncUpdateBlockReward(pageStore.CurrentSet.address, pageStore.CurrentSet.abi);
                    }

                    let toBuy = null;
                    if (buyCYC) {
                      toBuy = pageStore.BNBtoBuyCYC.value.plus(coinDenomination).toFixed();
                    } else {
                      toBuy = coinDenomination.toFixed();
                    }

                    const abi = await God.asyncGetABI(pageStore.CurrentSet.UniswapV2CycloneRouter.abi);
                    God.depositBSC(
                      pageStore.CurrentSet.UniswapV2CycloneRouter.address,
                      abi,
                      pageStore.CurrentSet.address,
                      note.commitment,
                      toBuy,
                      buyCYC,
                      res => {
                        addNewTransaction(
                          res,
                          amount,
                          cycDenomination,
                          note.commitment,
                          abi,
                          note.note,
                          true,
                          coinDenomination
                        );
                        pageStore.setModalViewShow(false);
                        setAttachView(null);
                      }
                    );
                    // God.depositBSC1(
                    //   pageStore.CurrentSet.address,
                    //   pageStore.CurrentSet.abi,
                    //   note.commitment,
                    //   toBuy,
                    //   res => {
                    //     addNewTransaction(res, amount, cycDenomination, note.commitment, abi, note.note, true);
                    //     pageStore.setModalViewShow(false);
                    //     setAttachView(null);
                    //   }
                    // );
                  }}
                  text={lang.t('Loading.text2.step3.eth')}
                  cancelable={true}
                  cancel={() => {
                    pageStore.setModalViewShow(false);
                    setAttachView(null);
                  }}
                />
              </ModalView>
            );
          }}
        />
      </ModalView>
    );
  };

  const onSwitchTab = indexOfTab => {
    // if (indexOfTab === 0) {
    //   resizeAsBigger(false)
    // }
    pageStore.setCurrentTab(indexOfTab);
  };

  const transactionNotFound = () => {
    pageStore.setModalViewShow(true);
    setAttachView(
      <ModalView>
        <Loading
          text={lang.t('Loading.text3')}
          cancelable={true}
          cancel={() => {
            pageStore.setModalViewShow(false);
            setAttachView(null);
          }}
        />
      </ModalView>
    );
  };

  const withdrawStart = (deposit, recipient, netId, poolId, amount, note) => {
    pageStore.setModalViewShow(true);
    setAttachView(
      <ModalView>
        <Loading
          action={async () => {
            God.checkFiles(async () => {
              const relayer = await God.getRelayer();
              const args = await God.generateProof(
                {
                  deposit: deposit,
                  recipient: recipient,
                  relayerAddress: relayer,
                  fee: EthereumConfig.tokensOnEthereum[netId].pools[poolId].relayerFee || EthereumConfig.relayerFee
                },
                // pageStore.CurrentSet.address,
                EthereumConfig.tokensOnEthereum[netId].pools[poolId].address,
                // pageStore.CurrentSet.abi,
                EthereumConfig.tokensOnEthereum[netId].pools[poolId].abi,
                netId
              );

              if (!args) {
                return transactionNotFound();
              }

              withdraw(args, relayer, netId, poolId, amount, note);
            });
          }}
          text={lang.t('Loading.text4')}
        />
      </ModalView>
    );
  };

  const removeTransactionRecord = idx => {
    pageStore.transactions.splice(idx, 1);
    pageStore.updateTransactions([...pageStore.transactions]);
  };

  const sortTransactionByField = idOfField => {
    if (pageStore.transactions.length < 2) {
      return;
    }

    let desc;
    switch (idOfField) {
      case 0:
        desc =
          pageStore.transactions[0].createTime > pageStore.transactions[pageStore.transactions.length - 1].createTime;
        if (desc) {
          pageStore.transactions = pageStore.transactions.sort((a, b) => a.createTime - b.createTime);
        } else {
          pageStore.transactions = pageStore.transactions.sort((a, b) => b.createTime - a.createTime);
        }
        break;

      case 1:
        desc = pageStore.transactions[0].amount > pageStore.transactions[pageStore.transactions.length - 1].amount;
        if (desc) {
          pageStore.transactions = pageStore.transactions.sort((a, b) => a.amount - b.amount);
        } else {
          pageStore.transactions = pageStore.transactions.sort((a, b) => b.amount - a.amount);
        }
        break;

      case 3:
        desc =
          pageStore.transactions[0].subsequent > pageStore.transactions[pageStore.transactions.length - 1].subsequent;
        if (desc) {
          pageStore.transactions = pageStore.transactions.sort((a, b) => a.subsequent - b.subsequent);
        } else {
          pageStore.transactions = pageStore.transactions.sort((a, b) => b.subsequent - a.subsequent);
        }
        break;

      case 4:
        desc = pageStore.transactions[0].status > pageStore.transactions[pageStore.transactions.length - 1].status;
        if (desc) {
          pageStore.transactions = pageStore.transactions.sort((a, b) => a.status - b.status);
        } else {
          pageStore.transactions = pageStore.transactions.sort((a, b) => b.status - a.status);
        }
        break;

      default:
        return;
    }

    // return pageStore.updateTransactions([...pageStore.transactions]);
  };

  const showSimpleAlert = text => {
    pageStore.setModalViewShow(true);
    setAttachView(
      <ModalView>
        <Alert
          onClose={() => {
            pageStore.setModalViewShow(false);
            setAttachView(null);
          }}
          text={text}
        />
      </ModalView>
    );
  };

  const withdraw = (args, viaRelayer, netId, poolId, amount, note) => {
    pageStore.setModalViewShow(true);
    setAttachView(
      <ModalView>
        <Alert
          onClose={() => {
            pageStore.setModalViewShow(true);
            setAttachView(
              <ModalView>
                <Loading
                  action={() => {
                    if (!viaRelayer) {
                      God.withdraw(
                        Config.tokensOnEthereum[netId].pools[poolId].address,
                        Config.tokensOnEthereum[netId].pools[poolId].abi,
                        args,
                        res => {
                          // addNewTransaction(res, amount, null, null, note, false)
                          updateTransaction(res, note);
                          pageStore.setModalViewShow(false);
                          setAttachView(null);

                          if (theWithdrawComponent) {
                            theWithdrawComponent.cleanup();
                          }
                        }
                      );
                    } else {
                      God.withdrawViaRelayer(
                        // Config.tokensOnEthereum[pageStore.chainID].tokens[indexOfSet].amountSteps[indexOfDenomination].address,
                        Config.tokensOnEthereum[netId].pools[poolId].address,
                        args,
                        res => {
                          if (!res) {
                            return showSimpleAlert(lang.t('internal.relayer.error'));
                          }

                          //addNewTransaction(res, amount, null, null, note, false)
                          updateTransaction(res, note);
                          pageStore.setModalViewShow(false);
                          setAttachView(null);

                          if (theWithdrawComponent) {
                            theWithdrawComponent.cleanup();
                          }
                        }
                      );
                    }
                  }}
                  text={viaRelayer ? lang.t('viaRelayer1') : lang.t('viaRelayer2')}
                  cancelable={true}
                  cancel={() => {
                    pageStore.setModalViewShow(false);
                    setAttachView(null);
                  }}
                />
              </ModalView>
            );
          }}
          title={lang.t('withdraw.confirmation')}
          text={lang.t('Loading.text5')}
        />
      </ModalView>
    );
  };

  // const resizeAsBigger = asBigger => {
  //   setContainerAsBigger(asBigger)
  // }

  const onStake = (amount, LPTokenAddress, LPTokenABI, { allowance }) => {
    if (new BigNumber(allowance).comparedTo(new BigNumber(amount)) >= 0) {
      showFinalStakView({ amount });
    } else {
      pageStore.setModalViewShow(true);
      setAttachView(
        <ModalView>
          <Loading
            action={async () => {
              console.log('onStake()', amount, LPTokenAddress, pageStore.CurrentNetwork.Aeolus.address);

              God.preStake(
                pageStore.CurrentNetwork.Aeolus.address,
                // new BigNumber(amount).plus(new BigNumber("1000000000")),
                new BigNumber(amount).plus(new BigNumber('1000000000')).toFixed(),
                LPTokenAddress,
                LPTokenABI,
                txid => {
                  checkStakeReady(txid, amount);
                }
              );
            }}
            text={lang.t('Loading.text6.eth')}
            cancelable={true}
            cancel={() => {
              pageStore.setModalViewShow(false);
              setAttachView(null);
            }}
          />
        </ModalView>
      );
    }
  };

  const checkStakeReady = (actionHash, amount) => {
    const tx = new TransactionObject(0, 0, actionHash, 0, 0, null, null, null);
    setTimeout(() => {
      tx.getTransactionOnEthereum(transaction => {
        if (!transaction) {
          return showSimpleAlert(lang.t('approval.transaction.error'));
        }
        showFinalStakView({ amount });
      });
    }, 3000);
  };

  const showFinalStakView = ({ amount }) => {
    pageStore.setModalViewShow(true);
    setAttachView(
      <ModalView>
        <Loading
          text={lang.t('Loading.text7.eth')}
          cancelable={true}
          cancel={() => {
            pageStore.setModalViewShow(false);
            setAttachView(null);
          }}
          nextable={true}
          next={async () => {
            console.log(
              'onStake() checkStakeReady()',
              amount,
              pageStore.CurrentNetwork.Aeolus.address,
              pageStore.CurrentNetwork.Aeolus.abi
            );

            const abi = await God.asyncGetABI(pageStore.CurrentNetwork.Aeolus.abi);
            God.stake(
              pageStore.CurrentNetwork.Aeolus.address,
              abi,
              {
                _pid: 0,
                _amount: amount
              },
              result => {
                if (result) {
                  // checkStakeResult(result);
                  navigateToLiquidty();
                }
              }
            );
          }}
        />
      </ModalView>
    );
  };

  const checkStakeResult = actionHash => {
    const tx = new TransactionObject(0, 0, actionHash, 0, 0, null, null, null);
    // tx.getReceipt(receiptResult => {
    //   if (receiptResult === -1) {
    //     return showSimpleAlert(lang.t('staking.failed'));
    //   }

    //   tx.getActions(result => {
    //     if (result) {
    //       navigateToLiquidty();
    //     }
    //   });
    // });
    setTimeout(() => {
      tx.getTransactionOnEthereum(transaction => {
        if (!transaction) {
          return showSimpleAlert(lang.t('staking.failed'));
        }

        navigateToLiquidty();
      });
    }, 3000);
  };

  const onUnstake = txid => {
    pageStore.setModalViewShow(false);
    setAttachView(null);
  };

  const navigateToLiquidty = () => {
    pageStore.setModalViewShow(true);
    setAttachView(
      <ModalView>
        <LiquidtyViewForBSC
          chainID={pageStore.chainID}
          isAvailable={pageStore.isConnect}
          stake={onStake}
          unstake={onUnstake}
          connect={connectIoPay}
          onClickCloseButton={() => {
            pageStore.setModalViewShow(false);
            setAttachView(null);
          }}
        />
      </ModalView>
    );
  };

  return (
    <>
      <div className="App" style={{ filter: pageStore.isModalViewShow ? 'blur(5px)' : 'none' }}>
        <div className={mainStyles.mainView}>
          <div className={navStyles.title}>
            <NavigatorBar
              apy={pageStore.liquidityApy}
              navigateToLiquidty={navigateToLiquidty}
              isConnect={pageStore.isConnect}
              onClick={!pageStore.isConnect ? connectIoPay : pageStore.logout}
            />
          </div>

          <div className={mainStyles.mainBlock}>
            <div className={mainStyles.mainContainer}>
              <div className="notice" style={{ marginBottom: '2rem' }}>
                {lang.t('notice1')}
                <span style={{ margin: '0 5px' }}>
                  <a href="https://docs.cyclone.xyz/audit">{lang.t('audited.code')}</a>
                </span>
                {lang.t('notice2')}
              </div>
              <div className="notice" style={{ marginTop: '1rem', marginBottom: '2rem' }}>
                {lang.t('mining.start.around')}
                <span style={{ textDecoration: 'auto !important', color: '#FFCA4E' }}>{pageStore.liquidityApy}%. </span>
                <span onClick={navigateToLiquidty} className={globalStyle.a}>
                  Get Started!
                </span>
              </div>

              <Chain />

              <div className={mainStyles.mainPanel}>
                <div className="mainTabBarLeft">
                  <TabBar onChange={onSwitchTab} />

                  {pageStore.currentTab === 0 && (
                    <DepositViewForBSC
                      chainID={pageStore.chainID}
                      isAvaliable={pageStore.isConnect}
                      onApproveXRC={approveXRC}
                      onApproveCYC={approveCYC}
                      onDeposit={onDeposit}
                      onConnect={connectIoPay}
                    />
                  )}

                  {pageStore.currentTab === 1 && (
                    <WithdrawViewForETH
                      ref={node => (theWithdrawComponent = node)}
                      chainID={pageStore.chainID}
                      // tokens={EthereumConfig.tokensOnEthereum[pageStore.chainID]}
                      // contractAddress={
                      //   Config.tokensOnEthereum[pageStore.chainID].tokens[pageStore.currentSelectedToken].amountSteps[
                      //     pageStore.currentSelectedAmount
                      //   ].address
                      // }
                      // abi={
                      //   Config.tokensOnEthereum[pageStore.chainID].tokens[pageStore.currentSelectedToken].amountSteps[
                      //     pageStore.currentSelectedAmount
                      //   ].abi
                      // }
                      withdrawStart={withdrawStart}
                      isAvaliable={pageStore.isConnect}
                      onConnect={connectIoPay}
                    />
                  )}
                </div>

                <div className="mainTabBarRight">
                  <div className="mainTabBarWrapper">
                    <div className="panelTitle">
                      <div style={{ color: '#45bcb8' }}>{pageStore.CurrentSet.symbol}</div>
                      <div>&nbsp;{lang.t('Statistics')}</div>
                    </div>

                    <div className="records">
                      {pageStore.gettingLogsDone && pageStore.records.length >= 0 && (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row'
                            }}
                          >
                            <div>
                              <div className="header">
                                {lang.t('anonymity.set')}
                                <span className="tooltip" data-tip="React-tooltip">
                                  i
                                </span>
                              </div>

                              <ReactTooltip place="top" type="warning" effect="solid" backgroundColor="#45bcb8">
                                <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                  {lang.t('number.of.deposits.your')}
                                  <br />
                                  {lang.t('withdrawal.will.potentially')}
                                  <br />
                                  {lang.t('originate.from')}
                                </div>
                              </ReactTooltip>

                              <div className="highLightText">
                                {pageStore.numOfRecords} {lang.t('equal.user.deposits')}{' '}
                              </div>
                              <div className="header" style={{ marginTop: '1rem' }}>
                                {lang.t('latest.deposits')}
                              </div>
                            </div>

                            {God.isBSC ? (
                              <div style={{ marginLeft: '1rem' }}>
                                <div className="header">
                                  {lang.t('total.apy')}:&nbsp;
                                  <CountUp end={pageStore.curPoolApy} duration={2} decimals={2} preserveValue />%
                                </div>
                              </div>
                            ) : null}
                          </div>

                          <div className="columnsTable">
                            <div style={{ display: 'table' }}>
                              {pageStore.makeTableList.map(item => (
                                <div style={{ display: 'table-row' }} key={item[0].leafIndex}>
                                  <div style={{ display: 'table-cell' }}>
                                    {item[0].leafIndex}.&nbsp;
                                    <span style={{ color: '#45bcb8' }}>
                                      {base.timeAgo.format(new Date(item[0].timestamp * 1000))}
                                    </span>
                                  </div>

                                  {item[1] && (
                                    <div style={{ display: 'table-cell', paddingLeft: '1rem' }}>
                                      {item[1].leafIndex}.&nbsp;
                                      <span style={{ color: '#45bcb8' }}>
                                        {base.timeAgo.format(new Date(item[1].timestamp * 1000))}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </>
                      )}

                      {!pageStore.gettingLogsDone && <div>{lang.t('loading')}...</div>}
                    </div>

                    <YourIp base={base} />
                  </div>
                </div>
              </div>

              {pageStore.transactions.length > 0 && (
                <div className="resultPanel">
                  <TransactionRecord
                    data={pageStore.transactions}
                    sortTransactionByField={sortTransactionByField}
                    removeTransactionRecord={removeTransactionRecord}
                  />
                </div>
              )}

              <Footer />
            </div>
          </div>
          <iframe id="opener" title="opener" width="0px" height="0px" style={{ opacity: 0 }} />
        </div>
      </div>

      {pageStore.isModalViewShow && attachView}

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />

      {chainId === 97 ? (
        <div
          style={{
            backgroundColor: 'black',
            color: 'white',
            position: 'absolute',
            right: 0,
            top: 0,
            textAlign: 'right',
            padding: '1rem',
            fontSize: '0.8rem',
            fontFamily: 'monospace'
          }}
        >
          <div>Network ID: {pageStore.chainID}</div>
          <div>Network: {God.getNetworkNameWithChainID(pageStore.chainID)}</div>
          <div>Account: {pageStore.account}</div>
        </div>
      ) : null}
    </>
  );
});
