import Antenna from 'iotex-antenna';
import { WsSignerPlugin } from 'iotex-antenna/lib/plugin/ws';
import Config from './Config';
import { JsBridgeSignerMobile } from './js-plugin';
import Utility from './Utility';
import { hexToBytes } from 'web3-utils';
import crypto from 'crypto';
import circomlib from 'circomlib';
import snarkjs from 'snarkjs';
import websnarkUtils from 'websnark/src/utils';
import merkleTree from './lib/MerkleTree';
import BigNumber from 'bignumber.js';
import WalletConnectProvider from '@walletconnect/web3-provider';
// import buildGroth16 from 'websnark/src/groth16'
// const buildGroth16 = require('websnark/src/groth16')
import buildGroth16 from './lib/groth16_browser';
import { backendApi } from './lib/backend';
import EthereumConfig from './EthereumConfig';
import axios from 'axios';
import { _ } from './lib/lodash';
import request, { gql } from 'graphql-request';
// const merkleTree = require("./lib/MerkleTree")
const Address = require('iotex-antenna/lib/crypto/address');
const bigInt = snarkjs.bigInt;
const unstringifyBigInts2 = require('snarkjs/src/stringifybigint').unstringifyBigInts;

const God = {
  antenna: null,
  jsSigner: null,
  isConnect: false,
  isMobile:
    navigator.userAgent && (navigator.userAgent.includes('IoPayAndroid') || navigator.userAgent.includes('IoPayiOs')),
  isAndroid: navigator.userAgent && navigator.userAgent.includes('IoPayAndroid'),
  ip: '',
  geo: '',
  logs: [],
  rewardLogs: [],
  theLastContract: '',
  provingKey: null,
  isLoadingProvingKey: false,
  circuit: null,
  isLoadingCircuit: false,
  verificationKey: null,
  isLoadingVerificationKey: false,
  currentRootIndex: null,
  latestGettingCurrentRootIndex: null,
  CYCABI: null,
  ABIs: new Map(),
  poolLength: 0,
  pool: [Config.LPToken.address],
  tokenNames: new Map(),
  countRetry: 0,
  isEthereum: false,
  isBSC: false,
  logsOnEthereum: null,
  web3: null,
  contractsOnEthereum: null,
  web3Provider: null,
  defaultAccount: "0xf06CB410fFd503b64753A53663073DaAfB8bF02f",
  chainID: EthereumConfig.defaultChainID,

  reset: function(){
    this.isEthereum = false
    this.isBSC = false
    return this
  },

  init: function () {

    this.ABIs = new Map();
    // this.pool = [Config.LPToken.address];
    this.tokenNames = new Map();
    this.logsOnEthereum = new Map();
    this.contractsOnEthereum = new Map();

    this.getAntena();

    // setTimeout(() => {
    //   this.preLoad();
    // }, 1000);
  },

  // coin: {
  //   async getIotexMarketPrice() {
  //     const res = await axios.get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=iotex');
  //     return res.data ? _.get(res.data, '0.current_price', 0) : null;
  //   }
  // },

  // preLoad: async function() {
  //   await this.readVerificationKey();
  //   await this.readCircuit();
  //   await this.readProvingKey();
  // },

  getIP: function (callback) {
    if (this.ip !== '') {
      return callback();
    } else {
      fetch(Config.ipAPI)
        .then(response => {
          response.json().then(res => {
            this.ip = res.ip;

            if (res.city && res.region_code) {
              this.geo = res.city + ', ' + res.region_code;
              if (this.geo.length > 11) {
                this.geo = res.city;
              }
            }

            return callback();
          });
        })
        .catch(e => {
          console.error(e);
        });
    }
  },

  getGeo: function (callback) {
    if (this.geo !== '') {
      return callback();
    } else {
      fetch(Config.GeoAPI.replace('{ip}', this.ip))
        .then(response => {
          response.json().then(res => {
            this.geo = res.city + ', ' + res.region_code;
            return callback();
          });
        })
        .catch(e => {
          console.error(e);
        });
    }
  },

  detectConnectStatus: function () {
    if (this.jsSigner.ws.readyState !== 1) {
      return false;
    } else {
      return true;
    }
  },

  ethEnabled() {
    if (window.ethereum) {
      return true;
    }
    return false;
  },

  connectIoPay: function (callback) {
    this.checkConnecting(account => {
      return callback(account);
    });

    setTimeout(() => {
      if (!Utility.isIoPayMobile()) {
        if (!this.detectConnectStatus()) {
          return callback();
        }
      }
    }, 8000);
  },

  checkConnecting: async function (callback) {
    if (!this.antenna.iotx.accounts[0] || this.antenna.iotx.accounts[0] === undefined) {
      await this.getAntena();

      return setTimeout(() => {
        this.checkConnecting(callback);
      }, 3000);
    }

    return callback(this.antenna.iotx.accounts[0]);
  },

  getAntena: async function () {
    this.jsSigner = Utility.isIoPayMobile() ? new JsBridgeSignerMobile() : new WsSignerPlugin();
    this.antenna = new Antenna(Config.iotexRpcURL, {
      signer: this.jsSigner
    });
    await this.getAccounts();
  },

  getAccounts: async function () {
    let accounts = '';
    if (Utility.isIoPayMobile()) {
      accounts = await this.jsSigner.getIoAddressFromIoPay();
      this.antenna.iotx.accounts[0] = await this.jsSigner.getAccount(accounts);
    } else {
      accounts = await this.antenna.iotx.accounts;
    }

    if (accounts?.length === 0) {
      setTimeout(() => {
        this.getAccounts();
      }, 10000);
      return;
    }

    this.isConnect = true;
    return accounts[0];
  },

  rbigint: function (nbytes) {
    return snarkjs.bigInt.leBuff2int(crypto.randomBytes(nbytes));
  },

  pedersenHash: function (data) {
    return circomlib.babyJub.unpackPoint(circomlib.pedersenHash.hash(data))[0];
  },

  makeCommitment: function () {
    const preimage = Buffer.concat([this.rbigint(31).leInt2Buff(31), this.rbigint(31).leInt2Buff(31)]);
    return {
      preimage: preimage,
      commitment: this.pedersenHash(preimage)
    };
  },

  toHex: function (number, length) {
    // if (!number) {
    // 	return "0x0"
    // }
    const str = number instanceof Buffer ? number.toString('hex') : bigInt(number).toString(16);
    return '0x' + str.padStart(length * 2, '0');
  },

  makeNote: function (currency, amount, netId, poolId) {
    const tempObject = this.createDeposit({ nullifier: this.rbigint(31), secret: this.rbigint(31) });
    const commitment = this.toHex(tempObject.commitment, 32);
    const note = this.toHex(tempObject.preimage, 62);
    return {
      note: `cyclone-${currency}-${amount}-${netId}-${poolId}-${note}`,
      commitment: commitment
    };
  },

  makeNoteV2: function (netId, poolId) {
    const tempObject = this.createDeposit({ nullifier: this.rbigint(31), secret: this.rbigint(31) });
    const commitment = this.toHex(tempObject.commitment, 32);
    const note = this.toHex(tempObject.preimage, 62);
    return {
      note: `cyclone-${netId}-${poolId}-${note}`,
      commitment: commitment
    };
  },

  asyncUpdateBlockReward: async function (contractAddress, abi) {
    return await this.asyncCallEthereumContract(contractAddress, abi, 'updateBlockReward', true, null);
  },

  asyncApproveToken: async function (CYCAddress, abi, spender, amount) {
    const result = await this.asyncCallEthereumContract(CYCAddress, abi, 'approve', false, null, spender, amount);
    return result;
  },

  asyncGetPurchaseCost: async function (routerAddress, routerABI, CYCAddress) {
    return await this.asyncCallEthereumContract(routerAddress, routerABI, 'purchaseCost', true, null, CYCAddress);
  },

  depositBSC: async function (contractAddress, abi, cyclone, note, amount, buyCYC, callback) {
    console.log("depositBSC()", contractAddress, abi, cyclone, note, amount, buyCYC);
    //     depositBSC() 0x90f6Ee4Ba9ef699383cA62df6dF1619bfA5781b5 Array(6) []
    //  0x0B3e3884235E6Dc21AD430320515ca63869877Af 0x0c1f7541dc97134c2dc92cd7c84c38d4b3809a22ea40a9ffdff469d65908f49e null false

    const result = await this.asyncCallEthereumContract(
      contractAddress,
      abi,
      'deposit',
      false,
      amount,
      cyclone,
      note,
      buyCYC
    );
    if (result) {
      callback(result);
    }
  },

  depositBSC1: async function (cyclone, abi, note, amount, callback) {
    const result = await this.asyncCallEthereumContract(cyclone, abi, 'deposit', false, amount, note);
    if (result) {
      callback(result);
    }
  },

  depositETH: async function (contractAddress, amount, note, abi, callback) {
    const result = await this.asyncCallEthereumContract(contractAddress, abi, 'deposit', false, amount, note, 0);
    if (result) {
      callback(result);
    }
  },

  depositIOTX: function (contractAddress, amount, note, abi, callback) {
    console.log(contractAddress, amount, note);
    this.antenna.iotx
      .executeContract(
        {
          contractAddress: contractAddress,
          amount: amount,
          abi: abi,
          method: 'deposit',
          gasLimit: '2000000',
          gasPrice: '1000000000000',
          from: this.antenna.iotx.accounts[0].address
        },
        note,
        0
      )
      .then(res => {
        callback(res);
      })
      .catch(err => {
        console.error(err);
      });
  },

  depositStep1: async function (contractAddress, amount, tokenAddress, tokenABI, callback) {
    const gasPrice = await God.getGasPrice();
    this.antenna.iotx
      .executeContract(
        {
          contractAddress: tokenAddress,
          amount: '0',
          abi: tokenABI,
          method: 'approve',
          gasLimit: '1000000',
          gasPrice: gasPrice.gasPrice.toString(),
          from: this.antenna.iotx.accounts[0].address
        },
        contractAddress,
        amount
      )
      .then(res => {
        callback(res);
      })
      .catch(err => {
        console.error(err);
      });
  },

  depositStep2: async function (contractAddress, note, abi, callback) {
    const gasPrice = await God.getGasPrice();
    this.antenna.iotx
      .executeContract(
        {
          contractAddress: contractAddress,
          amount: '0',
          abi: abi,
          method: 'deposit',
          gasLimit: '2000000',
          gasPrice: gasPrice.gasPrice.toString(),
          from: this.antenna.iotx.accounts[0].address
        },
        note
      )
      .then(res => {
        callback(res);
      })
      .catch(err => {
        console.error(err);
      });
  },

  getLogs: async function (contractAddress, force) {
    if (contractAddress === this.theLastContract && this.logs.length > 0 && !force) {
      return this.logs;
    }

    this.logs = [];

    const res = await backendApi.request({
      url: `/iotex/getDepositLogs`,
      params: { contractAddress, fromBlock: 9100001 }
    });

    if (res.data) {
      this.logs = res.data;
    }
    this.theLastContract = contractAddress;

    return this.logs;
  },

  // getLogs: async function (contractAddress, force) {
  // 	if (contractAddress === this.theLastContract && this.logs.length > 0 && !force) {
  // 		return this.logs
  // 	}

  // 	const topicBytes = sha3("Deposit(bytes32,uint32,uint256,uint256)")
  // 	const res = await this.antenna.iotx.getLogs({
  // 		filter: {
  // 			address: [contractAddress],
  // 			topics: [
  // 				{ topic: [Buffer.from(topicBytes.substring(2, topicBytes.length), "hex")] }
  // 			]
  // 		},
  // 		byRange: {
  // 			fromBlock: 7000000,
  // 			paginationSize: 1000,
  // 		}
  // 	})

  // 	this.logs = []
  // 	for (let l of res.logs) {
  // 		let decoded = Web3EthAbi.decodeLog([
  // 			{
  // 				"indexed": true,
  // 				"internalType": "bytes32",
  // 				"name": "commitment",
  // 				"type": "bytes32"
  // 			},
  // 			{
  // 				"indexed": false,
  // 				"internalType": "uint32",
  // 				"name": "leafIndex",
  // 				"type": "uint32"
  // 			},
  // 			{
  // 				"indexed": false,
  // 				"internalType": "uint256",
  // 				"name": "timestamp",
  // 				"type": "uint256"
  // 			},
  // 			{
  // 				"indexed": false,
  // 				"internalType": "uint256",
  // 				"name": "denomination",
  // 				"type": "uint256"
  // 			}
  // 		],
  // 			Buffer.from(l.data).toString('hex'),
  // 			Buffer.from(l.topics[1]).toString('hex')
  // 		)
  // 		decoded.commitment = '0x' + decoded.commitment
  // 		this.logs.push(decoded)
  // 	}

  // 	this.logs.sort((a, b) => a.leafIndex - b.leafIndex)
  // 	this.theLastContract = contractAddress

  // 	return this.logs
  // },

  getIsKnownRoot: async function (contractAddress, abi, root) {
    return await this.asyncLaunchContractMethod(contractAddress, abi, 'isKnownRoot', root);
    // try {
    //   const res = this.antenna.iotx.executeContract(
    //     {
    //       contractAddress: contractAddress,
    //       amount: '0',
    //       abi: abi,
    //       method: 'isKnownRoot',
    //       gasLimit: '1000000',
    //       gasPrice: '1000000000000',
    //       from:
    //         this.antenna.iotx.accounts && this.antenna.iotx.accounts[0] && this.antenna.iotx.accounts[0].address
    //           ? this.antenna.iotx.accounts[0].address
    //           : Config.defaultAccount,
    //     },
    //     root
    //   );
    //   return res;
    // } catch (error) {
    //   console.error(error);
    //   return null;
    // }
  },

  getIsSpent: async function (contractAddress, abi, nullifierHash) {
    return await this.asyncLaunchContractMethod(contractAddress, abi, 'isSpent', nullifierHash);
    // try {
    //   const res = this.antenna.iotx.executeContract(
    //     {
    //       contractAddress: contractAddress,
    //       amount: '0',
    //       abi: abi,
    //       method: 'isSpent',
    //       gasLimit: '1000000',
    //       gasPrice: '1000000000000',
    //       from:
    //         this.antenna.iotx.accounts && this.antenna.iotx.accounts[0] && this.antenna.iotx.accounts[0].address
    //           ? this.antenna.iotx.accounts[0].address
    //           : Config.defaultAccount,
    //     },
    //     nullifierHash
    //   );
    //   return res;
    // } catch (error) {
    //   console.error(error);
    //   return null;
    // }
  },

  getCurrentRootIndex: async function (contractAddress, abi) {
    if (
      this.currentRootIndex &&
      new Date().getTime() - this.latestGettingCurrentRootIndex > Config.intervalForContract
    ) {
      return this.currentRootIndex;
    }

    try {
      const res = this.antenna.iotx.executeContract({
        contractAddress: contractAddress,
        amount: '0',
        abi: abi,
        method: 'currentRootIndex',
        gasLimit: '1000000',
        gasPrice: '1000000000000',
        from: this.antenna.iotx.accounts[0].address
      });
      this.latestGettingCurrentRootIndex = new Date().getTime();
      return res;
    } catch (error) {
      console.error(error);
      return null;
    }
  },

  findOneLog: async function (deposit, contractAddress, netId) {
    let history = [];
    if (this.isEthereum) {
      const {logs} = await this.getLogsOnEthereum(contractAddress, true, 0, netId);
      history = logs;
    } else {
      history = await this.getLogs(contractAddress, true);
    }

    const theLog = history.find(e => e.commitment === this.toHex(deposit.commitment, 32));
    return [theLog, history];
  },

  findOneDepositEvent: async function (deposit, contractAddress, netId) {
    console.log("=====================findOneDepositEvent()", this.toHex(deposit.commitment, 32), netId);

    let history = [];
    if (this.isEthereum) {
      const {logs} = await this.getLogsOnEthereum(contractAddress, true, 0, netId);
      history = logs;
    } else {
      history = await this.getLogs(contractAddress);
    }

    const leaves = history.map(e => e.commitment);
    console.log("findOneDepositEvent() leaves =", leaves);

    const tree = new merkleTree(Config.merkleTreeHeight, leaves);
    const depositEvent = history.find(e => e.commitment === this.toHex(deposit.commitment, 32));
    console.log("findOneDepositEvent()", depositEvent, tree);

    return { depositEvent, tree };
  },

  generateMerkleProof: async function (deposit, contractAddress, abi, netId) {
    const depositEventBundle = await this.findOneDepositEvent(deposit, contractAddress, netId);
    const depositEvent = depositEventBundle.depositEvent;
    console.log("getIsKnownRoot depositEvent =", depositEvent);

    const tree = depositEventBundle.tree;
    console.log("getIsKnownRoot tree =", tree);

    const leafIndex = depositEvent ? depositEvent.leafIndex : -1;
    console.log("getIsKnownRoot leafIndex =", leafIndex);

    const root = await tree.root();
    console.log("getIsKnownRoot root =", root);

    console.log("getIsKnownRoot", contractAddress, abi, this.toHex(root, 32));
    const isValidRoot = await this.getIsKnownRoot(contractAddress, abi, this.toHex(root, 32));
    console.log("getIsKnownRoot =", isValidRoot);

    const isSpent = await this.getIsSpent(contractAddress, abi, this.toHex(deposit.nullifierHash, 32));
    if (isValidRoot === true && isSpent === false && leafIndex >= 0) {
      const treePath = await tree.path(leafIndex);
      return treePath;
    } else {
      return null;
    }
  },

  createDeposit: function ({ nullifier, secret }) {
    const deposit = { nullifier, secret };
    deposit.preimage = Buffer.concat([deposit.nullifier.leInt2Buff(31), deposit.secret.leInt2Buff(31)]);
    deposit.commitment = this.pedersenHash(deposit.preimage);
    deposit.commitmentHex = this.toHex(deposit.commitment, 32);
    deposit.nullifierHash = this.pedersenHash(deposit.nullifier.leInt2Buff(31));
    deposit.nullifierHex = this.toHex(deposit.nullifierHash, 32);
    return deposit;
  },

  getGasPrice: async function () {
    try {
      const res = this.antenna.iotx.suggestGasPrice({});
      return res;
    } catch (error) {
      console.error(error);
      return null;
    }
  },

  parseNote: function (noteString) {
    const noteRegex = /cyclone-(?<currency>\w+)-(?<amount>[\d.]+)-(?<netId>\d+)-(?<poolId>\d+)-0x(?<note>[0-9a-fA-F]{124})/g;
    const match = noteRegex.exec(noteString);
    if (!match) {
      console.error('The note has invalid format');
      return {
        error: true
      };
    }

    const buf = Buffer.from(match.groups.note, 'hex');
    const nullifier = bigInt.leBuff2int(buf.slice(0, 31));
    const secret = bigInt.leBuff2int(buf.slice(31, 62));
    const deposit = this.createDeposit({ nullifier, secret });
    const networkId = Number(match.groups.netId);

    return {
      currency: match.groups.currency,
      amount: match.groups.amount,
      networkId,
      poolId: Number(match.groups.poolId),
      deposit
    };
  },

  parseNoteV2: function (noteString) {
    const noteRegex = /cyclone-(?<netId>\w+)-(?<poolId>[\d.]+)-0x(?<note>[0-9a-fA-F]{124})/g;
    const match = noteRegex.exec(noteString);
    if (!match) {
      console.error('The note has invalid format');
      return {
        error: true
      };
    }

    const buf = Buffer.from(match.groups.note, 'hex');
    const nullifier = bigInt.leBuff2int(buf.slice(0, 31));
    const secret = bigInt.leBuff2int(buf.slice(31, 62));
    const deposit = this.createDeposit({ nullifier, secret });
    const networkId = Number(match.groups.netId);

    return {
      netId: networkId,
      poolId: match.groups.poolId,
      deposit
    };
  },

  readProvingKey: async function () {
    if (this.provingKey) {
      return this.provingKey;
    }

    this.isLoadingProvingKey = true;

    let response = await fetch(Config.assetURL + '/circuits/withdraw_proving_key.bin');
    let ab = await response.arrayBuffer();
    this.provingKey = ab;
    this.isLoadingProvingKey = false;

    return this.provingKey;

    // this.provingKey = await (await fetch(Config.assetURL + '/circuits/withdraw_proving_key.bin', {
    // 	headers: {
    // 		"Accept-Encoding": "gzip"
    // 	}
    // })).arrayBuffer()
    // return this.provingKey
  },

  readVerificationKey: async function () {
    if (this.verificationKey) {
      return this.verificationKey;
    }

    this.isLoadingVerificationKey = true;
    this.verificationKey = unstringifyBigInts2(
      await (await fetch(Config.assetURL + '/circuits/withdraw_verification_key.json')).json()
    );
    this.isLoadingVerificationKey = false;
    return this.verificationKey;
  },

  snarkVerify: async function (proof) {
    proof = unstringifyBigInts2(proof);
    const verification_key = await this.readVerificationKey();
    return snarkjs['groth'].isValid(verification_key, proof, proof.publicSignals);
  },

  readCircuit: async function () {
    if (this.circuit) {
      return this.circuit;
    }

    this.isLoadingCircuit = true;
    this.circuit = await (await fetch(Config.assetURL + '/circuits/withdraw.json')).json();
    this.isLoadingCircuit = false;
    return this.circuit;
  },

  getRelayer: async function () {
    let theConfig = null;
    if (this.isEthereum) {
      theConfig = EthereumConfig.tokensOnEthereum[this.chainID];
    } else {
      theConfig = Config;
    }

    const relayerStatus = await (await fetch(theConfig.relayer + '/status')).json();
    const relayerAddress = relayerStatus.relayerAddress;

    return relayerAddress;
  },

  checkFiles: function (callback) {
    if (!this.isLoadingVerificationKey && !this.isLoadingCircuit && !this.isLoadingProvingKey) {
      return callback();
    } else {
      setTimeout(() => {
        return this.checkFiles(callback);
      }, 3000);
    }
  },

  generateProof: async function (
    { deposit, recipient, relayerAddress = 0, fee = this.isBSC ? 25 : 0, refund = 0 },
    contractAddress,
    abi,
    netId
  ) {
    const treePath = await this.generateMerkleProof(deposit, contractAddress, abi, netId);
    if (!treePath) {
      return null;
    }

    const { root, path_elements, path_index } = treePath;
    // Prepare circuit input
    const input = {
      // Public snark inputs
      root: root,
      nullifierHash: deposit.nullifierHash,
      recipient: this.isEthereum ? recipient : bigInt(recipient),
      relayer: this.isEthereum ? relayerAddress : bigInt(relayerAddress),
      fee: bigInt(fee),
      refund: bigInt(refund),

      // Private snark inputs
      nullifier: deposit.nullifier,
      secret: deposit.secret,
      pathElements: path_elements,
      pathIndices: path_index
    };

    const groth16 = await buildGroth16();

    // const circuit = require('./circuits/withdraw.json')
    const circuit = await this.readCircuit();
    // const proving_key = await (await fetch('circuits/withdraw_proving_key.bin')).arrayBuffer()
    const proving_key = await this.readProvingKey();

    const proofData = await websnarkUtils.genWitnessAndProve(groth16, input, circuit, proving_key);

    if (!this.snarkVerify(proofData)) {
      console.error('Proof verification failed');
      throw new Error('Proof verification failed');
    }
    const { proof } = websnarkUtils.toSolidityInput(proofData);

    // eth address -> io address
    const recipientBytes = await hexToBytes(this.toHex(input.recipient, 20));
    const ioRecipient = await Address.fromBytes(recipientBytes);
    const relayerBytes = await hexToBytes(this.toHex(input.relayer, 20));
    const ioRelayer = await Address.fromBytes(relayerBytes);
    const args = [
      proof,
      this.toHex(input.root, 32),
      this.toHex(input.nullifierHash, 32),
      this.isEthereum ? recipient : ioRecipient.string(),
      this.isEthereum ? relayerAddress : ioRelayer.string(),
      this.toHex(input.fee, 32),
      this.toHex(input.refund, 32)
    ];

    return args;
  },

  withdraw: function (contractAddress, abi, args, callback) {
    this.antenna.iotx
      .executeContract(
        {
          contractAddress: contractAddress,
          amount: '0',
          abi: abi,
          method: 'withdraw',
          gasLimit: '1000000',
          gasPrice: '1000000000000',
          from: this.antenna.iotx.accounts[0].address ? this.antenna.iotx.accounts[0].address : Config.defaultAccount
        },
        ...args
      )
      .then(res => {
        callback(res);
      })
      .catch(err => {
        console.error(err);
      });
  },

  withdrawViaRelayer: function (contractAddress, args, callback) {
    let theRelayer = '';
    if (this.isEthereum) {
      theRelayer = EthereumConfig.tokensOnEthereum[this.chainID].relayer;
    } else {
      theRelayer = Config.relayer;
    }

    fetch(theRelayer + '/relay', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        contract: contractAddress,
        proof: args[0],
        args: [args[1], args[2], args[3], args[4], args[5], args[6]]
      })
    })
      .then(response => {
        if (response.status === 200 || response.ok) {
          return response.json();
        } else {
          try {
            response.json().then(errorJson => {
              if (errorJson) {
                callback(errorJson);
              } else {
                callback(response.statusText);
              }
            });
          } catch (error) {
            console.warn(error);
            callback(response.statusText);
          }
        }
      })
      .then(json => {
        if (!json) {
          return callback(null);
        }

        if (!json.error) {
          return callback(json.actionHash);
        } else {
          console.warn(json.error);

          return callback();
        }
      })
      .catch(e => {
        console.warn(e);

        return callback();
      });
  },

  asyncGetBlockByNumber: async function (blockNumber) {
    const block = await this.web3.eth.getBlock(blockNumber, false);
    if (block) {
      return block;
    }
  },

  getTransactionByHash: function (hxid, callback) {
    this.web3.eth.getTransaction(hxid, (error, transaction) => {
      if (transaction) {
        return callback(transaction);
      }
    });
  },

  getActions: function (hxid, callback) {
    this.antenna.iotx
      .getActions({
        byHash: {
          actionHash: hxid,
          checkingPending: true
        }
      })
      .then(res => {
        return callback(res);
      });
  },

  getReceipt: function (hxid, callback) {
    this.antenna.iotx
      .getReceiptByAction({
        actionHash: hxid
      })
      .then(res => {
        if (res && res.receiptInfo && res.receiptInfo.receipt && res.receiptInfo.receipt.contractAddress) {
          this.countRetry = 0;
          callback(res.receiptInfo.receipt.contractAddress);
        } else if (res && res.receiptInfo && res.receiptInfo.receipt && res.receiptInfo.receipt.status === 1) {
          this.countRetry = 0;
          callback(res.receiptInfo.receipt.status);
        } else {
          console.warn('The receript data is incorrect：', res);

          if (this.countRetry < Config.retry) {
            setTimeout(() => {
              this.countRetry += 1;
              this.getReceipt(hxid, callback);
            }, 3000);
          } else {
            this.countRetry = 0;
            return callback(-1);
          }
        }
      })
      .catch(errTx => {
        console.error('The transaction id is maybe incorrect.', errTx);

        if (this.countRetry < Config.retry) {
          setTimeout(() => {
            this.countRetry += 1;
            this.getReceipt(hxid, callback);
          }, 3000);
        } else {
          this.countRetry = 0;
          return callback(-1);
        }
      });
  },

  getIndexOfSetByTokenName: function (tokenName) {
    if (this.isEthereum) {
      const tokens = EthereumConfig.tokensOnEthereum[God.chainID].pools;
      for (let i = 0; i < tokens.length; i++) {
        if (tokens[i].name === tokenName) {
          return i;
        }
      }
    }

    for (let i = 0; i < Config.tokens.length; i++) {
      if (Config.tokens[i].name === tokenName) {
        return i;
      }
    }
  },

  getIndexOfDenominationByAmount: function (indexOfSet, amount) {
    const set = Config.tokens[indexOfSet];
    for (let i = 0; i < set.amountSteps.length; i++) {
      if (set.amountSteps[i].amoutnValue === amount) {
        return i;
      }
    }
  },

  getCYCABI: async function () {
    if (this.CYCABI) {
      return this.CYCABI;
    }

    this.CYCABI = await (await fetch(Config.LPToken.abi)).json();
    return this.CYCABI;
  },

  getBalanceOf: function (tokenAddress, abi, callback) {
    try {
      this.antenna.iotx
        .executeContract(
          {
            contractAddress: tokenAddress,
            amount: '0',
            abi: abi,
            method: 'balanceOf',
            gasLimit: '1000000',
            gasPrice: '1000000000000',
            from: this.antenna.iotx.accounts[0].address
          },
          this.antenna.iotx.accounts[0].address
        )
        .then(res => {
          return callback(parseInt(res));
        })
        .catch(err => {
          console.error(err);
          return callback(null);
        });
    } catch (error) {
      console.error(error);
      return callback(null);
    }
  },

  asyncGetBalanceOfCoinOnEthereum: async function (accountAddress) {
    return await God.web3.eth.getBalance(accountAddress);
  },

  asyncGetBalanceOf: async function (tokenAddress, abi) {
    let balance
    if (this.isEthereum) {
      balance = await this.asyncCallEthereumContract(tokenAddress, abi, 'balanceOf', true, null, this.defaultAccount);
    } else {
      balance = await this.asyncLaunchContractMethod(
        tokenAddress,
        abi,
        'balanceOf',
        this.antenna.iotx.accounts[0].address
      );
    }
    return new BigNumber(balance || 0).toFixed();
  },

  preStake: async function (contractAddress, amount, tokenAddress, tokenABI, callback) {
    if (this.isEthereum) {
      return await this.asyncCallEthereumContract(tokenAddress, tokenABI, 'approve', false, null, contractAddress, amount).then(
        res => {
          return callback(res);
        }
      );
    }

    this.antenna.iotx
      .executeContract(
        {
          contractAddress: tokenAddress,
          amount: '0',
          abi: tokenABI,
          method: 'approve',
          gasLimit: '1000000',
          gasPrice: '1000000000000',
          from: this.antenna.iotx.accounts[0].address
        },
        contractAddress,
        amount
      )
      .then(res => {
        callback(res);
      })
      .catch(err => {
        console.error(err);
      });
  },

  stake: async function (tokenAddress, abi, args, callback) {
    if (this.isEthereum) {
      return await this.asyncCallEthereumContract(tokenAddress, abi, "deposit", false, null, args._amount).then(res => {
        return callback(res);
      });
    }

    try {
      this.antenna.iotx
        .executeContract(
          {
            contractAddress: tokenAddress,
            amount: '0',
            abi: abi,
            method: 'deposit',
            gasLimit: '1000000',
            gasPrice: '1000000000000',
            from: this.antenna.iotx.accounts[0].address
          },
          args._amount
        )
        .then(res => {
          return callback(res);
        })
        .catch(err => {
          console.error(err);
          return callback(null);
        });
    } catch (error) {
      console.error(error);
      return callback(null);
    }
  },

  unstake: async function (tokenAddress, abi, args, callback) {
    if (this.isEthereum) {
      return await this.asyncCallEthereumContract(tokenAddress, abi, 'withdraw', false, null, args._amount).then(res => {
        return callback(res);
      });
    }

    try {
      this.antenna.iotx
        .executeContract(
          {
            contractAddress: tokenAddress,
            amount: '0',
            abi: abi,
            method: 'withdraw',
            gasLimit: '1000000',
            gasPrice: '1000000000000',
            from: this.antenna.iotx.accounts[0].address
          },
          args._amount
        )
        .then(res => {
          return callback(parseInt(res));
        })
        .catch(err => {
          console.error(err);
          return callback(null);
        });
    } catch (error) {
      console.error(error);
      return callback(null);
    }
  },

  asyncGetABI: async function (abiURL) {
    if (!abiURL || abiURL === '') {
      return null;
    }

    if (!this.ABIs.has(abiURL)) {
      const abi = await (await fetch(abiURL)).json();
      this.ABIs.set(abiURL, abi);
    }

    return this.ABIs.get(abiURL);
  },

  launchContractMethod: function (tokenAddress, abi, method, args, callback) {
    try {
      this.antenna.iotx
        .executeContract(
          {
            contractAddress: tokenAddress,
            amount: '0',
            abi: abi,
            method: method,
            gasLimit: '1000000',
            gasPrice: '1000000000000',
            from: this.antenna.iotx.accounts[0].address
          },
          ...args
        )
        .then(res => {
          return callback(res);
        })
        .catch(err => {
          console.error(err);
          return callback(null);
        });
    } catch (error) {
      console.error(error);
      return callback(null);
    }
  },

  asyncLaunchContractMethod: async function (tokenAddress, abi, method, ...args) {
    console.log("asyncLaunchContractMethod()", this.isEthereum, tokenAddress, abi, method);

    if (this.isEthereum) {
      return await this.asyncCallEthereumContract(tokenAddress, abi, method, true, null, ...args);
    }

    try {
      const res = await this.antenna.iotx.executeContract(
        {
          contractAddress: tokenAddress,
          amount: '0',
          abi: abi,
          method: method,
          gasLimit: '1000000',
          gasPrice: '1000000000000',
          from: Config.defaultAccount
        },
        ...args
      );
      return res;
    } catch (error) {
      console.error(error);
      return null;
    }
  },

  asyncLaunchContractMethodOnIotex: async function (tokenAddress, abi, method, ...args) {
    try {
      const res = await this.antenna.iotx.executeContract(
        {
          contractAddress: tokenAddress,
          amount: '0',
          abi: abi,
          method: method,
          gasLimit: '1000000',
          gasPrice: '1000000000000',
          from: Config.defaultAccount
        },
        ...args
      );
      return res;
    } catch (error) {
      console.error(error);
      return null;
    }
  },

  getPoolLength: async function () {
    if (this.poolLength === 0) {
      const abi = await this.asyncGetABI(Config.Aeolus.abi);
      this.poolLength = await this.asyncGetPoolLength(Config.Aeolus.address, abi);
    }

    return this.poolLength;
  },

  asyncGetPoolLength: async function (tokenAddress, abi) {
    const poolLength = await this.asyncLaunchContractMethod(tokenAddress, abi, 'poolLength', null);
    return parseInt(poolLength);
  },

  asyncGetLPTokenByIndex: async function (indexOfLPToken) {
    const abi = await this.asyncGetABI(Config.Aeolus.abi);
    const result = await this.asyncLaunchContractMethod(Config.Aeolus.address, abi, 'poolInfo', indexOfLPToken);
    return result[0];
  },

  asyncGetPools: async function () {
    // if (this.pool.length > 0) {
    // 	return this.pool
    // }

    // const length = await this.getPoolLength()
    // let tempAddress = ""
    // for (let i = 0; i < length; i++) {
    // 	tempAddress = await this.asyncGetLPTokenByIndex(i)
    // 	this.pool.push(tempAddress)
    // }
    if (this.isEthereum) {
      this.pool = [EthereumConfig.tokensOnEthereum[this.chainID].LPToken.address];
    }

    return this.pool;
  },

  asyncGetEarnedByIndexOfLPToken: async function (indexOfLPToken) {
    let theConfig = null;
    if (this.isEthereum) {
      theConfig = EthereumConfig.tokensOnEthereum[this.chainID];
    } else {
      theConfig = Config;
    }

    const abi = await this.asyncGetABI(theConfig.Aeolus.abi);

    if (this.isEthereum) {
      return this.asyncCallEthereumContract(
        theConfig.Aeolus.address,
        abi,
        'pendingReward',
        true,
        null,
        this.defaultAccount
      );
    }

    const sushi = await this.asyncLaunchContractMethod(
      theConfig.Aeolus.address,
      abi,
      'pendingReward',
      this.antenna.iotx.accounts[0].address
    );
    return parseInt(sushi) === 0
      ? 0
      : new BigNumber(sushi)
        .dividedBy(1e18)
        .toFixed(9)
        .toString();
  },
  asyncGetRewardPerBlock: async function (indexOfLPToken) {
    let theConfig = null;
    if (this.isEthereum) {
      theConfig = EthereumConfig.tokensOnEthereum[this.chainID];
    } else {
      theConfig = Config;
    }

    const abi = await this.asyncGetABI(theConfig.Aeolus.abi);

    if (this.isEthereum) {
      return this.asyncCallEthereumContract(theConfig.Aeolus.address, abi, 'rewardPerBlock', true, null);
    }

    const data = await this.asyncLaunchContractMethod(
      theConfig.Aeolus.address,
      abi,
      'rewardPerBlock',
      this.antenna.iotx.accounts[0].address
    );
    return data;
  },
  asyncGetBalanceOfLPToken: async function ({ adderss }) {
    // const lpTokens = await this.asyncGetPools()
    // const tokenAddress = lpTokens[indexOfLPToken]
    // const abi = await this.asyncGetABI(Config.LPToken.abi)
    const abi = await this.asyncGetABI(Config.LPToken.abi);
    // const balance = await this.asyncLaunchContractMethod(tokenAddress, abi, "balanceOf", this.antenna.iotx.accounts[0].address)
    const balance = await this.asyncLaunchContractMethod(Config.LPToken.address, abi, 'balanceOf', adderss);
    // return parseInt(balance) === 0 ? 0 : new BigNumber(balance).dividedBy(1e18).toFixed(9).toString()
    return parseInt(balance) != NaN
      ? new BigNumber(balance)
        .dividedBy(1e18)
        .toFixed(9)
        .toString()
      : '0';
  },

  asyncGetBalanceByIndexOfLPToken: async function (indexOfLPToken) {
    let theConfig = null;
    if (this.isEthereum) {
      theConfig = EthereumConfig.tokensOnEthereum[this.chainID];
    } else {
      theConfig = Config;
    }

    const abi = await this.asyncGetABI(theConfig.CYCToken.abi);

    if (this.isEthereum) {
      return this.asyncCallEthereumContract(
        theConfig.CYCToken.address,
        abi,
        'balanceOf',
        true,
        null,
        this.defaultAccount
      );
    }

    const balance = await this.asyncLaunchContractMethod(
      theConfig.CYCToken.address,
      abi,
      'balanceOf',
      this.antenna.iotx.accounts[0].address
    );
    // return parseInt(balance) === 0 ? 0 : new BigNumber(balance).dividedBy(1e18).toFixed(9).toString()
    return parseInt(balance) != NaN
      ? new BigNumber(balance)
        .dividedBy(1e18)
        .toFixed(9)
        .toString()
      : '0';
  },

  asyncGetCYCApIncentiveRate: async function ({ address, abiUrl }) {
    if (this.isBSC) {
      return 0;
    }

    const abi = await this.asyncGetABI(abiUrl);
    const result = await this.asyncLaunchContractMethod(address, abi, 'apIncentiveRate');
    return result;
  },
  asyncGetCYCBuybackRate: async function ({ address, abiUrl }) {
    const abi = await this.asyncGetABI(abiUrl);
    const result = await this.asyncLaunchContractMethod(address, abi, 'buybackRate');
    return result;
  },
  asyncGetWithdrawDenomination: async function ({ address, abiUrl }) {
    const abi = await this.asyncGetABI(abiUrl);
    const result = await this.asyncLaunchContractMethod(address, abi, 'getWithdrawDenomination');
    return result;
  },
  asyncGetNumOfShares: async function ({ address, abiUrl }) {
    const abi = await this.asyncGetABI(abiUrl);
    if (this.isEthereum) {
      return this.asyncCallEthereumContract(
        address,
        abi,
        'numOfShares',
        true,
        null,
      );
    }
    const result = await this.asyncLaunchContractMethod(address, abi, 'numOfShares');
    return result;
  },
  asyncGetMaxNumOfShares: async function ({ address, abiUrl }) {
    const abi = await this.asyncGetABI(abiUrl);
    const result = await this.asyncLaunchContractMethod(address, abi, 'maxNumOfShares');
    return result;
  },

  asyncGetAllowance: async function ({ contractAddress, abi, targetAddress }) {
    if (this.isEthereum) {
      return this.asyncCallEthereumContract(
        contractAddress,
        abi,
        'allowance',
        true,
        null,
        this.defaultAccount,
        targetAddress
      );
    }

    const balance = await this.asyncLaunchContractMethod(
      contractAddress,
      abi,
      'allowance',
      this.antenna.iotx.accounts[0].address,
      targetAddress
    );
    // return parseInt(balance) === 0 ? 0 : new BigNumber(balance).dividedBy(1e18).toFixed(9).toString()
    return parseInt(balance) != NaN
      ? new BigNumber(balance)
        .dividedBy(1e18)
        .toFixed(9)
        .toString()
      : '0';
  },

  asyncGetAllowanceByIndexOfLPToken: async function (indexOfLPToken) {
    let theConfig = null;
    if (this.isEthereum) {
      theConfig = EthereumConfig.tokensOnEthereum[this.chainID];
    } else {
      theConfig = Config;
    }

    const abi = await this.asyncGetABI(theConfig.LPToken.abi);

    if (this.isEthereum) {
      return this.asyncCallEthereumContract(
        theConfig.LPToken.address,
        abi,
        'allowance',
        true,
        null,
        this.defaultAccount,
        theConfig.Aeolus.address
      );
    }

    const balance = await this.asyncLaunchContractMethod(
      theConfig.LPToken.address,
      abi,
      'allowance',
      this.antenna.iotx.accounts[0].address,
      theConfig.Aeolus.address
    );
    // return parseInt(balance) === 0 ? 0 : new BigNumber(balance).dividedBy(1e18).toFixed(9).toString()
    return parseInt(balance) != NaN
      ? new BigNumber(balance)
        .dividedBy(1e18)
        .toFixed(9)
        .toString()
      : '0';
  },

  asyncGetTotalSupplyByIndexOfLPToken: async function (indexOfLPToken) {
    let theConfig = null;
    if (this.isEthereum) {
      theConfig = EthereumConfig.tokensOnEthereum[this.chainID];
    } else {
      theConfig = Config;
    }

    const [abi, lpTokens] = await Promise.all([this.asyncGetABI(Config.LPToken.abi), this.asyncGetPools()]);
    const tokenAddress = lpTokens[indexOfLPToken];

    let balance;
    if (this.isEthereum) {
      balance = await this.asyncCallEthereumContract(tokenAddress, abi, 'totalSupply', true, null);
    } else {
      balance = await this.asyncLaunchContractMethod(tokenAddress, abi, 'totalSupply');
    }

    return parseInt(balance) === 0 ? 0 : new BigNumber(balance).dividedBy(1e18).toFixed(9);
  },

  asyncGetTotalCycSupplyOfMimo: async function () {
    const abi = await this.asyncGetABI(Config.CYCToken.abi);
    const balance = await this.asyncLaunchContractMethod(Config.CYCToken.address, abi, 'totalSupply');
    return parseInt(balance) === 0 ? 0 : new BigNumber(balance).dividedBy(1e18).toFixed(9);
  },

  asyncGetTotalSupplyOfMimo: async function (ignoreChain) {
    let theConfig = null;
    if (this.isEthereum && !ignoreChain) {
      theConfig = EthereumConfig.tokensOnEthereum[this.chainID];
    } else {
      theConfig = Config;
    }

    const abi = await this.asyncGetABI(theConfig.CYCToken.abi);

    if (this.isEthereum && !ignoreChain) {
      return this.asyncCallEthereumContract(
        theConfig.CYCToken.address,
        abi,
        'balanceOf',
        true,
        null,
        this.defaultAccount
      );
    }

    const balance = await this.asyncLaunchContractMethodOnIotex(
      theConfig.CYCToken.address,
      abi,
      'balanceOf',
      theConfig.mimoExchange.address
    );

    return parseInt(balance) === 0 ? 0 : new BigNumber(balance).dividedBy(1e18).toFixed(9);
  },

  asyncGetStakedByIndexOfLPToken: async function (indexOfLPToken) {
    let theConfig = null;
    if (this.isEthereum) {
      theConfig = EthereumConfig.tokensOnEthereum[this.chainID];
    } else {
      theConfig = Config;
    }

    const abi = await this.asyncGetABI(theConfig.Aeolus.abi);

    if (this.isEthereum) {
      return this.asyncCallEthereumContract(theConfig.Aeolus.address, abi, 'userInfo', true, null, this.defaultAccount);
    }

    const userInfo = await this.asyncLaunchContractMethod(
      theConfig.Aeolus.address,
      abi,
      'userInfo',
      this.antenna.iotx.accounts[0].address
    );

    return parseInt(userInfo[0]) === 0
      ? 0
      : new BigNumber(userInfo[0])
        .dividedBy(1e18)
        .toFixed(9)
        .toString();
  },

  asyncGetTokenNameByAddress: async function (tokenAddress) {
    if (this.tokenNames.has(tokenAddress)) {
      return this.tokenNames.get(tokenAddress);
    }

    let theConfig = null;
    if (this.isEthereum) {
      theConfig = EthereumConfig.tokensOnEthereum[this.chainID];
    } else {
      theConfig = Config;
    }

    const abi = await this.asyncGetABI(theConfig.LPToken.abi);

    if (this.isEthereum) {
      return this.asyncCallEthereumContract(tokenAddress, abi, 'name', true, null);
    }

    const name = await this.asyncLaunchContractMethod(tokenAddress, abi, 'name', null);
    if (name) {
      this.tokenNames.set(tokenAddress, name);
      return name;
    }
  },

  asyncRunApprove: async function (tokenAddress, abi, senderAddress, amount) {
    const done = await this.asyncLaunchContractMethod(tokenAddress, abi, 'approve', senderAddress, amount);
    return done;
  },

  asyncGetDepositDenomination: async function (tokenAddress, abi) {
    let method = 'getDepositParameters';
    if (this.isBSC) {
      method = 'tokenDenomination';
    }
    const denomination = await this.asyncLaunchContractMethod(tokenAddress, abi, method);

    if (this.isBSC) {
      return denomination;
    } else {
      return denomination ? denomination[0] : 0;
    }
  },

  asyncGetCYCDenomination: async function (tokenAddress, abi) {
    const denomination = await this.asyncLaunchContractMethod(tokenAddress, abi, 'cycDenomination');
    return denomination;
  },

  claim: async function (callback) {
    const abi = await this.asyncGetABI(Config.Aeolus.abi);
    try {
      this.antenna.iotx
        .executeContract(
          {
            contractAddress: Config.Aeolus.address,
            amount: '0',
            abi: abi,
            method: 'deposit',
            gasLimit: '1000000',
            gasPrice: '1000000000000',
            from: this.antenna.iotx.accounts[0].address
          },
          0,
          0
        )
        .then(res => {
          return callback(res);
        })
        .catch(err => {
          console.error(err);
          return callback(null);
        });
    } catch (error) {
      console.error(error);
      return callback(null);
    }
  },

  asyncGetX1WithinMimo: async function (amount) {
    const abi = await this.asyncGetABI(Config.mimoExchange.abi);
    const wanted = await this.asyncLaunchContractMethod(
      Config.mimoExchange.address,
      abi,
      'getIotxToTokenInputPrice',
      new BigNumber(amount).dividedBy(10)
    );

    let x1 = new BigNumber(wanted);
    const rawUnit = new BigNumber(10).pow(Config.CYCToken.decimals);
    if (!x1.comparedTo(new BigNumber(100).multipliedBy(rawUnit)) > 1) {
      x1 = new BigNumber(100).multipliedBy(rawUnit);
    }
    return x1;
  },

  asyncGetX2WithinMimo: async function (amount) {
    const abi = await this.asyncGetABI(Config.mimoExchange.abi);
    const wanted = await this.asyncLaunchContractMethod(
      Config.mimoExchange.address,
      abi,
      'getIotxToTokenInputPrice',
      new BigNumber(amount).multipliedBy(0.03)
    );

    let x2 = new BigNumber(wanted);
    const rawUnit = new BigNumber(10).pow(Config.CYCToken.decimals);
    if (!x2.comparedTo(new BigNumber(30).multipliedBy(rawUnit)) > 1) {
      x2 = new BigNumber(30).multipliedBy(rawUnit);
    }
    return x2;
  },

  asyncGetCYCtoWithdraw: async function (contractAddress, abi) {
    const cycDenomination = await this.asyncGetCYCDenomination(contractAddress, abi);

    return new BigNumber(cycDenomination);
  },

  asyncGetBurnWithinMimo: async function (amount) {
    let theConfig = null;
    if (this.isEthereum) {
      theConfig = EthereumConfig.tokensOnEthereum[this.chainID];
    } else {
      theConfig = Config;
    }

    const abi = await this.asyncGetABI(theConfig.mimoExchange.abi);
    const wanted = await this.asyncLaunchContractMethod(
      theConfig.mimoExchange.address,
      abi,
      'getIotxToTokenInputPrice',
      new BigNumber(amount).toFixed(0)
    );
    let toBurn = new BigNumber(wanted);
    // const rawUnit = new BigNumber(10).pow(Config.CYCToken.decimals);
    // if (!toBurn.comparedTo(new BigNumber(130).multipliedBy(rawUnit)) > 1) {
    //   toBurn = new BigNumber(130).multipliedBy(rawUnit);
    // }
    return toBurn;
  },

  asyncGetCycPriceWithinMimo: async function (amount) {
    let theConfig = null;
    if (this.web3) {
      theConfig = EthereumConfig.tokensOnEthereum[this.chainID];
    } else {
      theConfig = Config;
    }

    const abi = await this.asyncGetABI(theConfig.mimoExchange.abi);
    const wanted = await this.asyncLaunchContractMethod(
      theConfig.mimoExchange.address,
      abi,
      'getIotxToTokenInputPrice',
      new BigNumber(amount).toFixed(0)
    );
    let toBurn = new BigNumber(wanted);
    const rawUnit = new BigNumber(10).pow(theConfig.CYCToken.decimals);
    if (!toBurn.comparedTo(new BigNumber(130).multipliedBy(rawUnit)) > 1) {
      toBurn = new BigNumber(130).multipliedBy(rawUnit);
    }
    return toBurn;
  },

  asyncGetIOTXBalance: async function (address) {
    const { accountMeta } = await this.antenna.iotx.getAccount({
      address: !address ? this.antenna.iotx.accounts[0].address : address
    });
    return accountMeta.balance;
  },

  getRewardAddedLogs: async function () {
    if (this.rewardLogs.length > 0) {
      return this.rewardLogs;
    }

    this.logs = [];

    const res = await backendApi.request({
      url: `/iotex/getRewardAddedLogs`,
      params: { contractAddress: Config.Aeolus.address }
    });
    if (res.data) {
      this.rewardLogs = res.data;
    }

    return this.rewardLogs;
  },

  initETH: function () {
    this.web3Provider = new WalletConnectProvider({
      infuraId: EthereumConfig.infuraId,
      bridge: 'https://bridge.walletconnect.org'
    });
  },

  asyncGetBlockNumber: async function () {
    try {
      return await this.web3.eth.getBlockNumber();
    } catch (e) {
      return 5627000;
    }
  },

  getLogsOnEthereum: async function (contractAddress, force, howMany, netId) {
    console.log("getLogsOnEthereum()", contractAddress, force, howMany, netId);

    // if (this.logsOnEthereum && this.logsOnEthereum.has(contractAddress) && !force) {
    //   return this.logsOnEthereum.get(contractAddress);
    // }

    // const signature = God.web3.eth.abi.encodeEventSignature('Deposit(bytes32,uint32,uint256,uint256)');
    // const logs = await God.web3.eth.getPastLogs({
    //   fromBlock: deployedBlock,
    //   toBlock: deployedBlock + 5000,
    //   address: contractAddress,
    //   topics: [signature]
    // });

    const queryLogs = gql`
      query {
        deposits(sort: timestamp, orderDirection:desc, first: ` + howMany + `, where: {
          contract: "` + contractAddress + `"
        }) {
          totalCount
          deposits {
            commitment,leafIndex,timestamp,tokenDenomination,coinDenomination,cycDenomination
          }
        }
      }
    `;
    const res = await request(EthereumConfig.tokensOnEthereum[netId].APIURL, queryLogs);
    const logs = res.deposits.deposits;

    logs.forEach(item => {
      item.commitment = "0x" + item.commitment;
    });

    logs.sort((a, b) => a.leafIndex - b.leafIndex);

    console.log("getLogsOnEthereum() logs =", logs)
    return {
      count: res.deposits.totalCount,
      logs,
    };

    // let tempArray = [];
    // if (logs && logs.length > 0) {
    //   for (let log of logs) {
    //     let decoded = this.web3.eth.abi.decodeLog(
    //       [
    //         {
    //           indexed: true,
    //           internalType: 'bytes32',
    //           name: 'commitment',
    //           type: 'bytes32'
    //         },
    //         {
    //           indexed: false,
    //           internalType: 'uint32',
    //           name: 'leafIndex',
    //           type: 'uint32'
    //         },
    //         {
    //           indexed: false,
    //           internalType: 'uint256',
    //           name: 'timestamp',
    //           type: 'uint256'
    //         },
    //         {
    //           indexed: false,
    //           internalType: 'uint256',
    //           name: 'denomination',
    //           type: 'uint256'
    //         }
    //       ],
    //       log.data,
    //       log.topics[1]
    //     );
    //     // decoded.commitment = '0x' + decoded.commitment;
    //     tempArray.push(decoded);
    //   }

    //   tempArray.sort((a, b) => a.leafIndex - b.leafIndex);
    //   // this.logsOnEthereum.set(contractAddress, tempArray);
    // }
    // return tempArray;
  },

  asyncGetContract: async function (contractAddress, abi) {
    if (!this.web3) {
      return null;
    }

    let contract;
    // if (this.contractsOnEthereum.has(contractAddress)) {
    //   contract = this.contractsOnEthereum.get(contractAddress);
    // } else {
    contract = new this.web3.eth.Contract(abi, contractAddress);
    this.contractsOnEthereum.set(contractAddress, contract);
    // }

    return contract;
  },

  asyncCallEthereumContract: async function (contractAddress, abi, method, isCall, value, ...args) {
    let abiJSON = null;
    if (typeof abi === 'string') {
      abiJSON = await this.asyncGetABI(abi);
    } else {
      abiJSON = abi;
    }

    const contract = await this.asyncGetContract(contractAddress, abiJSON);
    try {
      let result = null;
      if (isCall) {
        result = await contract.methods[method](...args).call({
          from: this.defaultAccount
        });
        console.log({ method, result, args, contractAddress });

        return result;
      } else {
        let argObject = { from: this.defaultAccount };
        if (value) {
          argObject.value = value;
        }

        result = await contract.methods[method](...args).send(argObject);
        if (result && result.transactionHash) {
          return result.transactionHash;
        }
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  },

  asyncGetEthereumBalance: async function (address) {
    const balanceString = await this.web3.eth.getBalance(address);
    const balance = new BigNumber(balanceString);
    if (!isNaN(balance)) {
      return balance;
    } else {
      return null;
    }
  },

  getNetworkNameWithChainID: function (id) {
    let network = '...';
    switch (id) {
      case 1:
        network = 'Mainnet';
        break;

      case 3:
        network = 'Ropsten';
        break;

      case 23:
        network = 'localhost';
        break;

      default:
        network = 'Ethereum ChainID: ' + id;
        break;
    }
    return network;
  },

  calculateWithdrawA({ numOfShares, maxNumOfShares }) {
    const p = numOfShares / maxNumOfShares;
    if (p >= 1 / 2) {
      return 1 / 2 - p / 2;
    } else if (p >= 1 / 4) {
      return 3 / 4 - p;
    }

    return 1 - 2 * p;
  },

  shortenString(string, begin, end) {
    let length = string.length;
    return string.substring(0, begin) + '...' + string.substring(length - end, length);
  }
};

export default God;
