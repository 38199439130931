import { LPTokenState, TokenState } from '../store/lib/TokenState';
import { ContractState, AeolusContractState } from '../store/lib/ContractState';
import AeolusV2ABI from '../../public/contracts/AeolusV2.json';
import PancakePairABI from '../../public/contracts/pancakePair.json';
import XRC20ABI from '../../public/contracts/XRC20.json';
import CYCLONEABI from '../../public/contracts/IOTXCyclone.json';
import { PoolState } from '../store/lib/PoolState';
import { ChainState } from '../store/lib/ChainState';
import CYCLONEV2Dot2ABI from '../../public/contracts/CycloneV2dot2.json';
import UniswapV2CycloneRouterABI from '../../public/contracts/UniswapV2CycloneRouter.json';

export const IotexMainnetConfig = new ChainState({
  name: 'Iotex Mainnet',
  chainId: 4689,
  key: 'iotex',
  rpcUrl: 'https://babel-api.mainnet.iotex.io/',
  explorerURL: 'https://iotexscan.io',
  explorerName: 'IotexScan',
  swapName: 'MimoSwap',
  swapURL: 'https://mimo.exchange/#/swap?outputCurrency=0x4D7b88403AA2F502bf289584160db01ca442426c',
  APIURL: 'https://analytics-iotex.cyclone.xyz/query',
  relayer: 'https://cyclone-iotex-web3-relayer.onrender.com',
  metas: {
    rewardPerBlock: 5
  },
  Coin: new TokenState({
    symbol: 'IOTX',
    decimals: 18
  }),
  Aeolus: new AeolusContractState({
    deprecated: true,
    name: 'CYC-IOTX LP V1 (Inactive)',
    lpName: 'CYC/IOTX Mimo V1 LP token',
    address: '0x9286e9271bf497ec39b3fdaef53e38bfc6b4cf14',
    removeLiquidity: 'https://v1.mimo.exchange/remove-liquidity?source=iotx&dest=${token}',
    abi: AeolusV2ABI
  }),
  AeolusV2: new AeolusContractState({
    deprecated: true,
    name: 'CYC-IOTX LP V2 (Inactive)',
    lpName: 'CYC/IOTX Mimo V1 LP token',
    address: '0x1a1dd52afad4f243c31f0906d019c4acdf81484b',
    migrateV2: true,
    removeLiquidity: 'https://v1.mimo.exchange/remove-liquidity?source=iotx&dest=${token}',
    abi: AeolusV2ABI
  }),
  AeolusV3: new AeolusContractState({
    name: 'CYC-IOTX LP V3',
    lpName: 'CYC/IOTX Mimo V2 LP token ',
    address: '0x8a6e1b15ac7681b13d134c0d5baaa61841958751',
    addLiquidity: 'https://mimo.exchange/#/add/IOTX/${token}',
    abi: AeolusV2ABI
  }),
  MultiCall: new ContractState({
    address: '0xacce294bf7d25fe8c5c64ae45197d3878f68403b'
  }),
  LPToken: new LPTokenState({
    address: '0x129db5e89eae1782fea4afc9e8a2c4985e1adfb2',
    abi: PancakePairABI,
    decimals: 18
  }),
  LPTokenV2: new LPTokenState({
    address: '0x129db5e89eae1782fea4afc9e8a2c4985e1adfb2',
    abi: PancakePairABI,
    decimals: 18
  }),
  LPTokenV3: new LPTokenState({
    address: '0x1381b170681074fedaf1c4e35be1880bc4e85c4a',
    abi: PancakePairABI,
    decimals: 18
  }),
  CYCToken: new TokenState({
    address: '0x4d7b88403aa2f502bf289584160db01ca442426c',
    abi: XRC20ABI,
    decimals: 18,
    symbol: 'CYC'
  }),
  UniswapV2CycloneRouter: new ContractState({
    address: '0x517CeeE661b57ed7d5b615bF700cb307D87a025B',
    abi: UniswapV2CycloneRouterABI
  }),
  pools: {
    '3': new PoolState({
      id: 3,
      version: 1,
      set: 'Pool 1- Squid',
      address: 'io15w9kwskwl9tn7luhcwrj0rarzjp988pafg07uf',
      abi: CYCLONEABI
    }),
    '4': new PoolState({
      id: 4,
      version: 1,
      set: 'Pool 2- Dolphin',
      address: 'io1wcd67wk36e3r8eku8scv7g7azsfnqs7z3e38xg',
      abi: CYCLONEABI
    }),
    '5': new PoolState({
      id: 5,
      version: 1,
      set: 'Pool 3- Shark',
      address: 'io1v667xgkux8uv0gell53ew5tr090c69k85deezn',
      abi: CYCLONEABI
    }),
    '6': new PoolState({
      id: 6,
      version: 1,
      set: 'Pool 4- Whale',
      address: 'io1wnaks7kectrkxk5v4d7mh97jkqjl4p0690jxfx',
      abi: CYCLONEABI
    }),
    '0': new PoolState({
      id: 0,
      version: 1,
      set: 'Early Sparrow',
      address: 'io1rqm2keejw4jypgl0w6hr96r054ns63u0hqrfuy',
      abi: CYCLONEABI
    }),
    '1': new PoolState({
      id: 1,
      version: 1,
      set: 'Early Raven',
      address: 'io1gkeeljp4grwskgq3tl2xqglqy546nhhmytnqqp',
      abi: CYCLONEABI
    }),
    '2': new PoolState({
      id: 2,
      version: 1,
      set: 'Early Eagle',
      address: 'io1mlcgsv4ma0t6gffpxg2gva3lwqnlj5msradxk6',
      abi: CYCLONEABI
    }),
    '16': new PoolState({
      id: 16,
      version: 2.3,
      set: 'Biscuit - 50,000 IOTX',
      address: '0x8fc2ac1cf90766492237a9156f93a6a4147424c5',
      abi: CYCLONEV2Dot2ABI,
      symbol: 'IOTX',
      decimals: 18,
      rawRelayerFee: '1000000000000000000'
    }),
    '17': new PoolState({
      id: 17,
      version: 2.3,
      set: 'Snickerdoodle - 500,000 IOTX',
      address: '0x92a737097d711bec4c31351997254e98e5f0d430',
      abi: CYCLONEV2Dot2ABI,
      symbol: 'IOTX',
      decimals: 18,
      rawRelayerFee: '1000000000000000000'
    }),
    '18': new PoolState({
      id: 18,
      version: 2.3,
      set: 'Gingersnaps - 2,000,000 VITA',
      address: '0x96de31c4f4ebaac302c29d586511f0f4ba77e579',
      XRCToken: new TokenState({
        abi: XRC20ABI,
        address: '0xb8744ae4032be5e5ef9fab94ee9c3bf38d5d2ae0',
        decimals: 18,
        symbol: 'VITA'
      }),
      abi: CYCLONEV2Dot2ABI,
      symbol: 'VITA',
      decimals: 18,
      rawRelayerFee: '1000000000000000000'
    }),
    '106': new PoolState({
      id: 106,
      version: 2.3,
      set: 'Test',
      dev: true,
      address: '0x8b144b25598DbB20DC6AD579Cc5e78C077b6925b',
      abi: CYCLONEV2Dot2ABI,
      symbol: 'IOTX',
      decimals: 18,
      rawRelayerFee: '1000000000000000000'
    })
  }
});
