import circomlib from "circomlib"
import snarkjs from "snarkjs"

const mimcsponge = circomlib.mimcsponge
const bigInt = snarkjs.bigInt

class MimcSpongeHasher {
  hash(level, left, right) {
    return mimcsponge.multiHash([bigInt(left), bigInt(right)]).toString()
  }
}

export default MimcSpongeHasher