import { createClient } from '../../generated/bsc/gql/createClient';
import { request } from 'graphql-request';
import Config from '../Config';

export const analyticsBscClient = createClient({
  fetcher: ({ query, variables }) => request(Config.analyticsBscURL, query, variables).then((data) => ({ data }))
});

export const analyticsETHClient = createClient({
  fetcher: ({ query, variables }) => request(Config.analyticsETHURL, query, variables).then((data) => ({ data }))
});

export const analyticsPolygonClient = createClient({
  fetcher: ({ query, variables }) => request(Config.analyticsPolygonURL, query, variables).then((data) => ({ data }))
});

export const analyticsIOTEXV2CLient = createClient({
  fetcher: ({ query, variables }) => request('https://analytics-iotex.cyclone.xyz/query', query, variables).then((data) => ({ data }))
});

export const createAnalyticsEthCilent = ({ APIURL }) =>
  createClient({
    fetcher: ({ query, variables }) => request(APIURL, query, variables).then((data) => ({ data }))
  });
