import { ETHNetworkState } from '../store/lib/NetworkState';
import { BSCMainnetConfig } from './BSCMainnetConfig';
import { BSCTestnetConfig } from './BSCTestnetConfig';
import { IotexNetworkState } from '../store/lib/IotexNetworkState';
import { IotexMainnetConfig } from './IotexMainnetConfig';
import { ETHKovanCongfig } from './ETHKovanConfig';
import { ETHMainnetConfig } from './ETHMainConfig';
import { MaticTestnetConfig } from './MaticTestnetConfig';
import { MaticMainnetConfig } from './MaticMainnetConfig';

// latest poolId: 23
// latest test poolId: 105

export const EthereumConfigV2 = new ETHNetworkState({
  defaultChainId: BSCMainnetConfig.chainId,
  allowChains: [
    BSCMainnetConfig.chainId,
    BSCTestnetConfig.chainId,
    ETHMainnetConfig.chainId,
    ETHKovanCongfig.chainId,
    MaticMainnetConfig.chainId,
    IotexMainnetConfig.chainId
  ],
  chains: {
    [BSCMainnetConfig.chainId]: BSCMainnetConfig,
    [BSCTestnetConfig.chainId]: BSCTestnetConfig,
    [ETHMainnetConfig.chainId]: ETHMainnetConfig,
    [ETHKovanCongfig.chainId]: ETHKovanCongfig,
    [MaticMainnetConfig.chainId]: MaticMainnetConfig,
    [IotexMainnetConfig.chainId]: IotexMainnetConfig
  }
});

export const IotexConfigV2 = new IotexNetworkState({
  defaultChainId: IotexMainnetConfig.chainId,
  allowChains: [IotexMainnetConfig.chainId],
  chains: {}
});
