import snarkjs from 'snarkjs';
import crypto from 'crypto';
import circomlib from 'circomlib';

const bigInt = snarkjs.bigInt;

export interface DepositNote {
  nullifier: any;
  secret: any;
  preimage: any;
  commitment: any;
  commitmentHex: any;
  nullifierHash: any;
  nullifierHex: any;
}

export interface NoteData {
  netId: number;
  poolId: string;
  deposit: Partial<DepositNote>;
}

export const GodUtils = {
  isMobile:
    navigator.userAgent && (navigator.userAgent.includes('IoPayAndroid') || navigator.userAgent.includes('IoPayiOs')),
  rbigint(nbytes) {
    return snarkjs.bigInt.leBuff2int(crypto.randomBytes(nbytes));
  },
  pedersenHash(data) {
    return circomlib.babyJub.unpackPoint(circomlib.pedersenHash.hash(data))[0];
  },
  toHex(number, length) {
    const str = number instanceof Buffer ? number.toString('hex') : bigInt(number).toString(16);
    return '0x' + str.padStart(length * 2, '0');
  },
  createDeposit({ nullifier, secret }): Partial<DepositNote> {
    const deposit: Partial<DepositNote> = { nullifier, secret };
    deposit.preimage = Buffer.concat([deposit.nullifier.leInt2Buff(31), deposit.secret.leInt2Buff(31)]);
    deposit.commitment = GodUtils.pedersenHash(deposit.preimage);
    deposit.commitmentHex = GodUtils.toHex(deposit.commitment, 32);
    deposit.nullifierHash = GodUtils.pedersenHash(deposit.nullifier.leInt2Buff(31));
    deposit.nullifierHex = GodUtils.toHex(deposit.nullifierHash, 32);
    return deposit;
  },
  makeNoteV2({ netId, poolId }) {
    const tempObject = GodUtils.createDeposit({ nullifier: GodUtils.rbigint(31), secret: GodUtils.rbigint(31) });
    const commitment = GodUtils.toHex(tempObject.commitment, 32);
    const note = GodUtils.toHex(tempObject.preimage, 62);
    return {
      note: `cyclone-${netId}-${poolId}-${note}`,
      commitment: commitment,
    };
  },
  parseNoteV1(noteString) {
    const noteRegex = /cyclone-(?<currency>\w+)-(?<amount>[\d.]+)-(?<netId>\d+)-(?<poolId>\d+)-0x(?<note>[0-9a-fA-F]{124})/g;
    const match = noteRegex.exec(noteString);
    if (!match) {
      console.error('The note has invalid format');
      return;
    }

    const buf = Buffer.from(match.groups.note, 'hex');
    const nullifier = bigInt.leBuff2int(buf.slice(0, 31));
    const secret = bigInt.leBuff2int(buf.slice(31, 62));
    const deposit = this.createDeposit({ nullifier, secret });
    const netId = Number(match.groups.netId);

    return {
      currency: match.groups.currency,
      amount: match.groups.amount,
      netId,
      poolId: match.groups.poolId,
      deposit,
    };
  },
  parseNoteV2(noteString) {
    const noteRegex = /cyclone-(?<netId>\w+)-(?<poolId>[\d.]+)-0x(?<note>[0-9a-fA-F]{124})/g;
    const match = noteRegex.exec(noteString);
    if (!match) {
      console.error('The note has invalid format');
      return null;
    }

    const buf = Buffer.from(match.groups.note, 'hex');
    const nullifier = bigInt.leBuff2int(buf.slice(0, 31));
    const secret = bigInt.leBuff2int(buf.slice(31, 62));
    const deposit = this.createDeposit({ nullifier, secret });
    const netId = Number(match.groups.netId);

    return {
      netId,
      poolId: match.groups.poolId,
      deposit,
    };
  },
};
