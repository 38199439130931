import { LPTokenState, TokenState } from '../store/lib/TokenState';
import { ContractState, AeolusContractState } from '../store/lib/ContractState';
import AeolusV2ABI from '../../public/contracts/AeolusV2.json';
import PancakePairABI from '../../public/contracts/pancakePair.json';
import XRC20ABI from '../../public/contracts/XRC20.json';
import UniswapV2CycloneRouterABI from '../../public/contracts/UniswapV2CycloneRouter.json';
import { PoolState } from '../store/lib/PoolState';
import { ChainState } from '../store/lib/ChainState';
import CYCLONEV2Dot2ABI from '../../public/contracts/CycloneV2dot2.json';

export const MaticMainnetConfig = new ChainState({
  name: 'Polygon',
  chainId: 137,
  rpcUrl: 'https://rpc-mainnet.maticvigil.com/',
  explorerURL: 'https://polygonscan.com',
  explorerName: 'PolygonScan',
  swapName: 'QuickSwap',
  swapURL: 'https://quickswap.exchange/#/swap/?inputCurrency=ETH&outputCurrency=0xcFb54a6D2dA14ABeCD231174FC5735B4436965D8',
  APIURL: 'https://analytics-polygon.cyclone.xyz/query',
  relayer: 'https://cyclone-polygon-relayer.onrender.com',
  metas: {
    rewardPerBlock: 2
  },
  Coin: new TokenState({
    symbol: 'MATIC',
    decimals: 18
  }),
  Aeolus: new AeolusContractState({
    addLiquidity: 'https://quickswap.exchange/#/add/ETH/${token}',
    address: '0xA8c187d8773bc9E49A10554715Ff49Bdcf39d55D'.toLowerCase(),
    abi: AeolusV2ABI
  }),
  MultiCall: new ContractState({
    address: '0xFBB4a19a2A5eF4D1861b6b6E1150e1E2d453FdB7'
  }),
  LPToken: new LPTokenState({
    address: '0x6eA88502BC127EF1a7f45fAC6B7168fc0633Ba51',
    abi: PancakePairABI,
    decimals: 18
  }),
  CYCToken: new TokenState({
    address: '0xcFb54a6D2dA14ABeCD231174FC5735B4436965D8',
    abi: XRC20ABI,
    decimals: 18,
    symbol: 'CYC'
  }),
  UniswapV2CycloneRouter: new ContractState({
    address: '0xacf00a84559f536ba64064a4c73b74698013ef36',
    abi: UniswapV2CycloneRouterABI
  }),
  pools: {
    '105': new PoolState({
      id: 105,
      version: 2.3,
      dev: true,
      set: 'Test Pool',
      address: '0x8A6e1b15aC7681b13D134c0D5bAAa61841958751',
      abi: CYCLONEV2Dot2ABI,
      symbol: 'MATIC',
      decimals: 18,
      rawRelayerFee: '10000'
    }),
    '106': new PoolState({
      id: 106,
      version: 2.3,
      dev: true,
      set: 'Test Pool 1',
      address: '0x438d30B10a6404facE43ee7156EEf9DaeE8BACd6',
      abi: CYCLONEV2Dot2ABI,
      symbol: 'MATIC',
      decimals: 18,
      rawRelayerFee: '10000000000000'
    }),
    '21': new PoolState({
      id: 21,
      version: 2.3,
      set: 'Pool Pentagon - 1,000 MATIC',
      address: '0x87059fDff1dC655ACf8652bA530d89f38de22f16',
      abi: CYCLONEV2Dot2ABI,
      symbol: 'MATIC',
      decimals: 18,
      rawRelayerFee: '100000000000000000'
    }),
    '22': new PoolState({
      id: 22,
      version: 2.3,
      set: 'Pool Hexagon - 1,000 USDC',
      address: '0xb5AEE5f2743A9f4abcE9C964d1530e3E96725Be7',
      abi: CYCLONEV2Dot2ABI,
      symbol: 'USDC',
      decimals: 6,
      logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png',
      XRCToken: new TokenState({
        address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
        abi: XRC20ABI,
        decimals: 6,
        symbol: 'USDC'
      }),
      rawRelayerFee: '100000000000000000'
    }),
    '23': new PoolState({
      id: 23,
      version: 2.3,
      set: 'Pool Octagon - 2 QUICK',
      address: '0x3E2c1b4dfA868A6ffD8CdC20D7BFa9Abb4462284',
      abi: CYCLONEV2Dot2ABI,
      symbol: 'QUICK',
      decimals: 18,
      logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8206.png',
      XRCToken: new TokenState({
        address: '0x831753dd7087cac61ab5644b308642cc1c33dc13',
        abi: XRC20ABI,
        decimals: 18,
        symbol: 'QUICK'
      }),
      rawRelayerFee: '100000000000000000'
    })
  }
});
