import React, { useEffect, useState } from 'react';
import NavigatorBar from '../../components/NavigatorBar';
import TabBar from '../../components/TabBar';
import DepositView from '../../components/DepositView';
import God from '../../God';
import ModalView from '../../components/ModalView';
import Loading from '../../components/Loading';
import Config from '../../Config';
import CheckNoteView from '../../components/CheckNoteView';
import TransactionRecord from '../../components/TransactionRecord';
import TransactionObject from '../../TransactionObject';
import WithdrawView from '../../components/WithdrawView';
import Alert from '../../components/Alert';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LiquidtyView from '../../components/LiquidtyView';
import { navStyles, mainStyles } from '../../styles/common';
import ReactTooltip from 'react-tooltip';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../store';
import Footer from '../../components/Footer';
import YourIp from '../../components/YourIp';
import DepositResult from '../../components/DepositResult';
import BigNumber from 'bignumber.js';
import { Chain } from '../../components/Chain';

let theWithdrawComponent = null;

export const IotexPage = observer(() => {
  const { base, iotex: pageStore, lang } = useStore();
  const [attachView, setAttachView] = useState(null);
  const [apy, setApy] = useState(-1);

  // const [biggerContainer, setContainerAsBigger] = useState(false)

  useEffect(() => {
    God.reset().init();
    base.init();
    lang.init();
    pageStore.init();
  }, []);

  useEffect(() => {
    pageStore.setModalViewShow(true);
    setAttachView(
      <ModalView>
        <Alert
          onClose={() => {
            pageStore.setModalViewShow(false);
          }}
          text={lang.t('iotex.v1.alert')}
        />
      </ModalView>
    );
  }, []);

  useEffect(() => {
    base.calculateAPY();
    pageStore.getLogs();
  }, [pageStore.currentSelectedToken, pageStore.currentSelectedPool]);

  const onConnect = (account) => {
    pageStore.setConnectionStatus(true);
    console.log('onConnect', account);
    // FOR TESTING...
    // God.getRewardAddedLogs()
    // showDepositResult("10000000000000000000000")
    // addNewTransaction("8d3af25ade1c2299af5b46a6bb1946124d38a5c22a226e549f90ddec923e96d9", "123456789012345678", "0xjakfhjksdhfasd", [], "0x888888888888", true);
    // return;
    pageStore.setModalViewShow(false);
    setAttachView(null);
  };

  const onNotConnect = () => {
    pageStore.setConnectionStatus(false);
    pageStore.setModalViewShow(false);
    setAttachView(
      <ModalView>
        <Loading
          action={() => {
            God.connectIoPay((account) => {
              if (account) {
                return onConnect(account);
              }
            });
          }}
          cancelable={true}
          cancel={() => {
            pageStore.setModalViewShow(false);
            pageStore.setConnectionStatus(false);
          }}
          text={
            <div>
              <p>{lang.t('connect.no.iopay')}</p>
              <p>
                {lang.t('connect.no.iopay.content')}&nbsp;
                <a href="https://iopay.iotex.io" target="_blank">
                  https://iopay.iotex.io
                </a>
              </p>
            </div>
          }
        />
      </ModalView>
    );
    pageStore.setModalViewShow(true);
  };

  const connectIoPay = () => {
    pageStore.setModalViewShow(true);
    setAttachView(
      <ModalView>
        <Loading
          action={() => {
            God.connectIoPay((account) => {
              if (account) {
                return onConnect(account);
              } else {
                console.log('unconnect');
              }
              return onNotConnect();
            });
          }}
          text={lang.t('iopay.connect')}
        />
      </ModalView>
    );
  };

  const addNewTransaction = (txid, amount, commitment, abi, note, isDeposit) => {
    let newTx = new TransactionObject(
      new Date().getTime(),
      amount,
      txid,
      pageStore.currentSelectedToken,
      pageStore.currentSelectedPool,
      commitment,
      abi,
      note,
      isDeposit
    );
    pageStore.transactions.unshift(newTx);
    pageStore.updateTransactions([...pageStore.transactions]);

    setTimeout(() => {
      newTx.getReceipt(() => {
        newTx.getActions(() => {
          pageStore.updateTransactions([...pageStore.transactions]);

          if (newTx.note) {
            setTimeout(() => {
              newTx.getSubsequent(() => {
                pageStore.updateTransactions([...pageStore.transactions]);
              });
            }, 3000);
          }
        });
      });
    }, 3000);
  };

  const updateTransaction = (res, note) => {
    let newTx = pageStore.transactions.find((item) => {
      return item.note === note;
    });

    if (!newTx) {
      return;
    }

    newTx.txHash = res;
    // newTx.status = 2;
    pageStore.updateTransactions([...pageStore.transactions]);

    setTimeout(() => {
      newTx.getReceipt(() => {
        newTx.getActions(() => {
          newTx.status = 3;
          pageStore.updateTransactions([...pageStore.transactions]);

          setTimeout(() => {
            updateAllSubsepents();
          }, 1000);
        });
      });
    }, 3000);
  };

  const updateAllSubsepents = () => {
    for (let i = 0; i < pageStore.transactions.length; i++) {
      const element = pageStore.transactions[i];
      if (element.note) {
        element.getSubsequent(() => {
          pageStore.updateTransactions([...pageStore.transactions]);
        });
      }
    }
  };

  const showDepositResult = async (amount) => {
    const rawUnit = new BigNumber(10).pow(Config.CYCToken.decimals);

    let i = await God.asyncGetX1WithinMimo(amount);
    const x1 = !isNaN(i) ? i.dividedBy(rawUnit).toFixed(6).toString() : 0;

    i = await God.asyncGetX2WithinMimo(amount);
    const x2 = !isNaN(i) ? i.dividedBy(rawUnit).toFixed(6).toString() : 0;

    pageStore.setModalViewShow(true);
    setAttachView(
      <ModalView>
        <DepositResult
          // amount={amount}
          x1={x1}
          x2={x2}
          goToLPMining={navigateToLiquidty}
          onClose={() => {
            pageStore.setModalViewShow(false);
            setAttachView(null);
          }}
        />
      </ModalView>
    );
  };

  const onDeposit = (contractAddress, amount, isIOTX) => {
    const token = pageStore.CurrentToken;
    const pool = pageStore.CurrentPool;
    const note = God.makeNote(token.name, amount, token.networkID, pool.poolId);

    pageStore.setModalViewShow(true);

    // download text
    if (note && !God.isMobile) {
      let blob = new Blob([note.note]);
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = `${lang.t('YourNote')}.txt`;
      a.click();
      window.URL.revokeObjectURL(url);
    }

    if (isIOTX) {
      setAttachView(
        <ModalView>
          <CheckNoteView
            title={lang.t('YourNote')}
            note={note.note}
            onClickCloseButton={() => {
              pageStore.setModalViewShow(false);
              setAttachView(null);
            }}
            onSend={() => {
              setAttachView(
                <ModalView>
                  <Loading
                    action={async () => {
                      const abi = await God.asyncGetABI(pool.abi);
                      God.depositIOTX(contractAddress, amount, note.commitment, abi, (res) => {
                        addNewTransaction(res, amount, note.commitment, abi, note.note, true);
                        setTimeout(() => {
                          showDepositResult(amount);
                        }, 1500);
                      });
                    }}
                    text={lang.t('Loading.text1')}
                    cancelable={true}
                    cancel={() => {
                      pageStore.setModalViewShow(false);
                      setAttachView(null);
                    }}
                  />
                </ModalView>
              );
            }}
          />
        </ModalView>
      );
    } else {
      setAttachView(
        <ModalView>
          <CheckNoteView
            title={lang.t('YourNote')}
            note={note.note}
            onClickCloseButton={() => {
              pageStore.setModalViewShow(false);
              setAttachView(null);
            }}
            onSend={() => {
              setAttachView(
                <ModalView>
                  <Loading
                    action={async () => {
                      const abi = pool.abi;
                      const tokenABI = await God.asyncGetABI(token.abi);

                      God.depositStep1(contractAddress, amount, token.address, tokenABI, (res) => {
                        checkStatusOfStep1(res, contractAddress, note, abi, amount);
                      });
                    }}
                    text={lang.t('Loading.text1')}
                    cancelable={true}
                    cancel={() => {
                      pageStore.setModalViewShow(false);
                      setAttachView(null);
                    }}
                  />
                </ModalView>
              );
            }}
          />
        </ModalView>
      );
    }
  };

  const checkStatusOfStep1 = (actionHash, contractAddress, note, abi, amount) => {
    const tx = new TransactionObject(0, 0, actionHash, 0, 0, null, null, null);
    tx.getReceipt(() => {
      tx.getActions(() => {
        pageStore.setModalViewShow(true);
        setAttachView(
          <ModalView>
            <Loading
              text={lang.t('Loading.text2')}
              cancelable={true}
              cancel={() => {
                pageStore.setModalViewShow(false);
                setAttachView(null);
              }}
              nextable={true}
              next={() => {
                God.depositStep2(contractAddress, note.commitment, abi, (res) => {
                  addNewTransaction(res, amount, note.commitment, abi, note.note, true);
                  pageStore.setModalViewShow(false);
                  setAttachView(null);
                });
              }}
            />
          </ModalView>
        );
      });
    });
  };

  const onSwitchTab = (indexOfTab) => {
    // if (indexOfTab === 0) {
    //   resizeAsBigger(false)
    // }
    pageStore.setCurrentTab(indexOfTab);
  };

  const transactionNotFound = () => {
    pageStore.setModalViewShow(true);
    setAttachView(
      <ModalView>
        <Loading
          text={lang.t('Loading.text3')}
          cancelable={true}
          cancel={() => {
            pageStore.setModalViewShow(false);
            setAttachView(null);
          }}
        />
      </ModalView>
    );
  };

  const withdrawStart = (deposit, recipient, indexOfSet, poolId, amount, note) => {
    pageStore.setModalViewShow(true);
    const token = Config.tokens[indexOfSet];
    const pool = token.amountSteps.find((i) => i.poolId == poolId);

    setAttachView(
      <ModalView>
        <Loading
          action={async () => {
            God.checkFiles(async () => {
              const relayer = await God.getRelayer();
              const abi = await God.asyncGetABI(pool.abi);
              const args = await God.generateProof(
                {
                  deposit: deposit,
                  recipient: recipient,
                  relayerAddress: relayer
                },
                pool.address,
                abi
              );

              if (!args) {
                return transactionNotFound();
              }

              withdraw(args, relayer, indexOfSet, poolId, amount, note);
            });
          }}
          text={lang.t('Loading.text4')}
        />
      </ModalView>
    );
  };

  const removeTransactionRecord = (idx) => {
    pageStore.transactions.splice(idx, 1);
    pageStore.updateTransactions([...pageStore.transactions]);
  };

  const sortTransactionByField = (idOfField) => {
    if (pageStore.transactions.length < 2) {
      return;
    }

    let desc;
    switch (idOfField) {
      case 0:
        desc = pageStore.transactions[0].createTime > pageStore.transactions[pageStore.transactions.length - 1].createTime;
        if (desc) {
          pageStore.transactions = pageStore.transactions.sort((a, b) => a.createTime - b.createTime);
        } else {
          pageStore.transactions = pageStore.transactions.sort((a, b) => b.createTime - a.createTime);
        }
        break;

      case 1:
        desc = pageStore.transactions[0].amount > pageStore.transactions[pageStore.transactions.length - 1].amount;
        if (desc) {
          pageStore.transactions = pageStore.transactions.sort((a, b) => a.amount - b.amount);
        } else {
          pageStore.transactions = pageStore.transactions.sort((a, b) => b.amount - a.amount);
        }
        break;

      case 3:
        desc = pageStore.transactions[0].subsequent > pageStore.transactions[pageStore.transactions.length - 1].subsequent;
        if (desc) {
          pageStore.transactions = pageStore.transactions.sort((a, b) => a.subsequent - b.subsequent);
        } else {
          pageStore.transactions = pageStore.transactions.sort((a, b) => b.subsequent - a.subsequent);
        }
        break;

      case 4:
        desc = pageStore.transactions[0].status > pageStore.transactions[pageStore.transactions.length - 1].status;
        if (desc) {
          pageStore.transactions = pageStore.transactions.sort((a, b) => a.status - b.status);
        } else {
          pageStore.transactions = pageStore.transactions.sort((a, b) => b.status - a.status);
        }
        break;

      default:
        return;
    }

    // return pageStore.updateTransactions([...pageStore.transactions]);
  };

  const showSimpleAlert = (text) => {
    pageStore.setModalViewShow(true);
    setAttachView(
      <ModalView>
        <Alert
          onClose={() => {
            pageStore.setModalViewShow(false);
            setAttachView(null);
          }}
          text={text}
        />
      </ModalView>
    );
  };

  const withdraw = (args, viaRelayer, indexOfSet, poolId, amount, note) => {
    pageStore.setModalViewShow(true);
    const token = Config.tokens[indexOfSet];
    const pool = token.amountSteps.find((i) => i.poolId == poolId);
    setAttachView(
      <ModalView>
        <Alert
          onClose={() => {
            pageStore.setModalViewShow(true);
            setAttachView(
              <ModalView>
                <Loading
                  action={() => {
                    if (!viaRelayer) {
                      God.withdraw(pool.address, pool.abi, args, (res) => {
                        // addNewTransaction(res, amount, null, null, note, false)
                        updateTransaction(res, note);
                        pageStore.setModalViewShow(false);
                        setAttachView(null);

                        if (theWithdrawComponent) {
                          theWithdrawComponent.cleanup();
                        }
                      });
                    } else {
                      God.withdrawViaRelayer(pool.address, args, (res) => {
                        if (!res) {
                          return showSimpleAlert(lang.t('internal.relayer.error'));
                        }

                        // if (typeof res === 'string') {
                        //   return showSimpleAlert(res);
                        // }

                        if (res.error) {
                          return showSimpleAlert(res.error);
                        }

                        updateTransaction(res, note);
                        // pageStore.setModalViewShow(false);
                        // setAttachView(null);

                        showSimpleAlert(lang.t('withdraw.result'));

                        if (theWithdrawComponent) {
                          theWithdrawComponent.cleanup();
                        }
                      });
                    }
                  }}
                  text={viaRelayer ? lang.t('viaRelayer1') : lang.t('viaRelayer2')}
                  cancelable={true}
                  cancel={() => {
                    pageStore.setModalViewShow(false);
                    setAttachView(null);
                  }}
                />
              </ModalView>
            );
          }}
          title={lang.t('withdraw.confirmation')}
          text={lang.t('Loading.text5')}
        />
      </ModalView>
    );
  };

  // const resizeAsBigger = asBigger => {
  //   setContainerAsBigger(asBigger)
  // }

  const onStake = async (amount, LPTokenAddress, LPTokenABI, { allowance }) => {
    if (Number(allowance) >= Number(amount)) {
      showFinalStakView({ amount });
    } else {
      pageStore.setModalViewShow(true);
      setAttachView(
        <ModalView>
          <Loading
            action={async () => {
              God.preStake(Config.Aeolus.address, amount, LPTokenAddress, LPTokenABI, (txid) => {
                checkStakeReady(txid, amount);
              });
            }}
            text={lang.t('Loading.text6')}
            cancelable={true}
            cancel={() => {
              pageStore.setModalViewShow(false);
              setAttachView(null);
            }}
          />
        </ModalView>
      );
    }
  };

  const checkStakeReady = (actionHash, amount) => {
    const tx = new TransactionObject(0, 0, actionHash, 0, 0, null, null, null);
    tx.getReceipt(() => {
      tx.getActions((result) => {
        if (!result) {
          return showSimpleAlert(lang.t('approval.transaction.error'));
        }
        showFinalStakView({ amount });
      });
    });
  };

  const showFinalStakView = ({ amount }) => {
    pageStore.setModalViewShow(true);
    setAttachView(
      <ModalView>
        <Loading
          text={lang.t('Loading.text7')}
          cancelable={true}
          cancel={() => {
            pageStore.setModalViewShow(false);
            setAttachView(null);
          }}
          nextable={true}
          next={async () => {
            const abi = await God.asyncGetABI(Config.Aeolus.abi);
            God.stake(
              Config.Aeolus.address,
              abi,
              {
                _pid: 0,
                _amount: amount
              },
              (result) => {
                if (result) {
                  checkStakeResult(result);
                }
              }
            );
          }}
        />
      </ModalView>
    );
  };

  const checkStakeResult = (actionHash) => {
    const tx = new TransactionObject(0, 0, actionHash, 0, 0, null, null, null);
    tx.getReceipt((receiptResult) => {
      if (receiptResult === -1) {
        return showSimpleAlert(lang.t('staking.failed'));
      }

      tx.getActions((result) => {
        if (result) {
          navigateToLiquidty();
        }
      });
    });
  };

  const onUnstake = (txid) => {
    pageStore.setModalViewShow(false);
    setAttachView(null);
  };

  const navigateToLiquidty = () => {
    pageStore.setModalViewShow(true);

    setAttachView(
      <ModalView>
        <LiquidtyView
          stake={onStake}
          unstake={onUnstake}
          onClickCloseButton={() => {
            pageStore.setModalViewShow(false);
            setAttachView(null);
          }}
        />
      </ModalView>
    );
  };

  return (
    <>
      <div className="App" style={{ filter: pageStore.isModalViewShow ? 'blur(5px)' : 'none' }}>
        <div className={mainStyles.mainView.className}>
          <div className={navStyles.title.className}>
            <NavigatorBar
              apy={base.apy === -1 ? '...' : base.apy}
              navigateToLiquidty={navigateToLiquidty}
              isConnect={pageStore.isConnect}
              onClick={!pageStore.isConnect ? connectIoPay : pageStore.logout}
            />
          </div>

          <div className={mainStyles.mainBlock.className}>
            <div>
              <div className="notice" style={{ marginBottom: '0' }}>
                {lang.t('notice1')}
                <span style={{ margin: '0 5px' }}>
                  <a href="https://docs.cyclone.xyz/audit">{lang.t('audited.code')}</a>
                </span>
                {lang.t('notice2')}
              </div>
              <div className="notice" style={{ marginTop: '1rem', marginBottom: '2rem' }}>
                {lang.t('notice.early.bird')}
                {lang.t('notice.early2.read')}
                <span style={{ margin: '0 5px' }}>
                  <a href="https://docs.cyclone.xyz">{lang.t('Docs')}</a>
                </span>
                {lang.t('notice.early2.tips')}
              </div>

              <Chain />

              <div className={mainStyles.mainPanel.className}>
                <div className="mainTabBarLeft">
                  <TabBar onChange={onSwitchTab} />

                  {pageStore.currentTab === 0 && <DepositView onDeposit={onDeposit} onConnect={connectIoPay} />}

                  {pageStore.currentTab === 1 && (
                    <WithdrawView
                      ref={(node) => (theWithdrawComponent = node)}
                      isAvaliable={pageStore.isConnect}
                      contractAddress={Config.tokens[pageStore.currentSelectedToken].amountSteps[pageStore.currentSelectedPool].address}
                      abi={Config.tokens[pageStore.currentSelectedToken].amountSteps[pageStore.currentSelectedPool].abi}
                      // resizeAsBigger={resizeAsBigger}
                      withdrawStart={withdrawStart}
                      onConnect={connectIoPay}
                    />
                  )}
                </div>

                <div className="mainTabBarRight">
                  <div className="mainTabBarWrapper">
                    <div className="panelTitle">
                      <div style={{ color: '#45bcb8' }}>
                        {Config.tokens[pageStore.currentSelectedToken].amountSteps[pageStore.currentSelectedPool].amount}
                        &nbsp;{Config.tokens[pageStore.currentSelectedToken].name}
                      </div>
                      <div>&nbsp;&nbsp;{lang.t('Statistics')}</div>
                    </div>

                    <div className="records">
                      {pageStore.records && pageStore.records.length > 0 && (
                        <>
                          <div className="header">
                            {lang.t('anonymity.set')}
                            <span className="tooltip" data-tip="React-tooltip">
                              i
                            </span>
                          </div>

                          <ReactTooltip place="top" type="warning" effect="solid" backgroundColor="#45bcb8">
                            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                              {lang.t('number.of.deposits.your')}
                              <br />
                              {lang.t('withdrawal.will.potentially')}
                              <br />
                              {lang.t('originate.from')}
                            </div>
                          </ReactTooltip>

                          <div className="highLightText">
                            {pageStore.records.length} {lang.t('equal.user.deposits')}{' '}
                          </div>
                          <div className="header" style={{ marginTop: '1rem' }}>
                            {lang.t('latest.deposits')}
                          </div>
                          <div className="columnsTable">
                            <div style={{ display: 'table' }}>
                              {pageStore.makeTableList.map((item) => (
                                <div style={{ display: 'table-row' }} key={item[0].leafIndex}>
                                  <div style={{ display: 'table-cell' }}>
                                    {Number(item[0].leafIndex) + 1}.&nbsp;
                                    <span style={{ color: '#45bcb8' }}>{base.timeAgo.format(new Date(item[0].timestamp * 1000))}</span>
                                  </div>

                                  {item[1] && (
                                    <div style={{ display: 'table-cell', paddingLeft: '1rem' }}>
                                      {Number(item[1].leafIndex) + 1}.&nbsp;
                                      <span style={{ color: '#45bcb8' }}>{base.timeAgo.format(new Date(item[1].timestamp * 1000))}</span>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </>
                      )}
                      {pageStore.records && pageStore.records.length == 0 && <div>{lang.t('no_deposits')}</div>}
                      {pageStore.records == null && <div>{lang.t('loading')}...</div>}
                    </div>

                    <YourIp base={base} />
                  </div>
                </div>
              </div>

              {pageStore.transactions.length > 0 && (
                <div className="resultPanel">
                  <TransactionRecord
                    data={pageStore.transactions}
                    sortTransactionByField={sortTransactionByField}
                    removeTransactionRecord={removeTransactionRecord}
                  />
                </div>
              )}
              <Footer />
            </div>
          </div>

          <iframe id="opener" title="opener" width="0px" height="0px" style={{ opacity: 0 }} />
        </div>
      </div>

      {pageStore.isModalViewShow && attachView}

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
    </>
  );
});
