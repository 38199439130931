import React from 'react';
import { observer } from 'mobx-react-lite';
import { Wrapper } from '../../../modules/globalStyle';
import { useStore } from '../../../store';
import { LpMining } from '../../../components/LpMining';
import { LpMiningHeader } from '../../../components/LpMiningHeader';
import { PoolList } from '../../../components/Poollist';

export const Deposit = observer(() => {
  const { lang, god, dev, base } = useStore();

  return (
    <Wrapper>
      <LpMiningHeader />
      {god.AeolusV3 && <LpMining aeolus={god.AeolusV3} />}
      {god.AeolusV2 && <LpMining aeolus={god.AeolusV2} />}
      <LpMining aeolus={god.Aeolus} />
      {/* head */}
      <PoolList title={lang.t('anonymity_pools')} pools={god.currentChain.poolListFitered} />
      {god.currentChain.pendingPools.length ? <PoolList title={lang.t('request.for.anonymity')} pools={god.currentChain.pendingPools} /> : ''}
    </Wrapper>
  );
});
