import React, { useState } from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import { Wrapper, Input, FlexBox, Text, BorderBox, Icon } from '../../../modules/globalStyle';
import { styled } from '../../../modules/index';
import { useStore } from '../../../store';
import { TransactionState } from '../../../store/lib/TransactionState';
import { message, Popconfirm } from 'antd';
import copy from 'copy-to-clipboard';

const TdBox = styled('div', {
  paddingLeft: '1rem',
  variants: {
    size: {
      xxs: {
        width: '5%'
      },
      xs: {
        width: '10%'
      },
      sm: {
        width: '15%'
      },
      md: {
        width: '20%'
      },
      lg: {
        width: '25%'
      }
    }
  }
});

export const NoteList = observer(() => {
  const { lang, transaction, base, god } = useStore();

  const store = useLocalStore(() => ({
    copy(item: TransactionState) {
      copy(item.note);
      message.success(lang.t('check.note.copied'));
    },
    onClickRemoveButton(item: TransactionState) {
      transaction.removeTransaction(item);
    }
  }));

  return (
    <Wrapper>
      {transaction.currentTransactions.map((item) => {
        return (
          <BorderBox css={{ padding: '2rem 0 0.5rem 0' }} key={item.txHash}>
            <FlexBox justify="center" items="start">
              <TdBox size="md">
                <Text size="mini" color="lightGray" weight="medium" css={{ mb: '0.6rem' }}>
                  {lang.t('time.passed')}
                </Text>
                <Text size="mini" color="gray">
                  {base.timeAgo.format(new Date(item.createTime))}
                </Text>
              </TdBox>
              <TdBox size="xs">
                <Text size="mini" color="lightGray" weight="medium" css={{ mb: '0.6rem' }}>
                  {lang.t('Pool')}
                </Text>
                <Text size="mini" color="gray">
                  {item.pool.set}
                </Text>
              </TdBox>
              <TdBox size="md">
                <Text size="mini" color="lightGray" weight="medium" css={{ mb: '0.6rem' }}>
                  {lang.t('denomination')}
                </Text>
                <Text size="mini" color="gray">
                  {item.amountToken && item.pool.XRCToken && (
                    <div>
                      {item.pool.XRCToken.parseValue({ value: item.amountToken })}&nbsp;
                      {item.pool.XRCToken.symbol.toUpperCase()}
                    </div>
                  )}
                  {item.amountCYC && (
                    <div>
                      {god.CYCToken.parseValue({ value: item.amountCYC })}&nbsp;
                      {god.CYCToken.symbol.toUpperCase()}
                    </div>
                  )}
                  {item.amountCoin && (
                    <div>
                      {god.Coin.parseValue({ value: item.amountCoin })}&nbsp;
                      {god.Coin.symbol.toUpperCase()}
                    </div>
                  )}
                </Text>
              </TdBox>
              {/* <TdBox size="md">
                <Text size="mini" color="lightGray" weight="medium" css={{ mb: '0.6rem' }}>
                  Subsequent Deposits
                </Text>
                <Text size="mini" color="gray">
                  xxxxxxxxxx
                </Text>
              </TdBox> */}
              <TdBox size="md">
                <Text size="mini" color="lightGray" weight="medium" css={{ mb: '0.6rem' }}>
                  {lang.t('tx.hash')}
                </Text>
                <Text size="mini" color="gray" overflow="truncate">
                  <a href={item.txUrl} className="colorfulA" target="_blank" rel="noopener noreferrer">
                    {item.txHash}
                  </a>
                </Text>
              </TdBox>
              <TdBox size="xs">
                <Text size="mini" color="lightGray" weight="medium" css={{ mb: '0.6rem' }}>
                  {lang.t('status')}
                </Text>
                <Text size="mini" color="gray">
                  {item.statusLabel}
                </Text>
              </TdBox>
              <TdBox size="xs">
                <FlexBox css={{ mt: '1.5rem', cursor: 'pointer' }} onClick={() => store.copy(item)}>
                  <Icon src="/images/home/icon_copy.png"></Icon>
                  <Text size="mini" color="primary" cursor="pointer">
                    {lang.t('note')}
                  </Text>
                </FlexBox>
              </TdBox>
              <TdBox size="xxs">
                <Popconfirm title={lang.t('note.delte.confirm')} onConfirm={() => store.onClickRemoveButton(item)}>
                  <Icon
                    src="/images/home/delete_button_n.png"
                    css={{
                      width: '1rem',
                      mt: '1.7rem',
                      '&:hover': {
                        content: 'url("/images/home/delete_button_s.png")'
                      }
                    }}
                  ></Icon>
                </Popconfirm>
              </TdBox>
            </FlexBox>
          </BorderBox>
        );
      })}
      {transaction.currentTransactions.length === 0 && (
        <>
          <BorderBox css={{ padding: '0 0 0.5rem 0' }}>
            <Text size="small" color="white">
              {lang.t('note')}
            </Text>
          </BorderBox>
          <FlexBox direction="column" justify="center" items="center" css={{ height: 300 }}>
            <Icon src="/images/home/empty.png" css={{ width: '4rem', mb: '2rem' }} alt=""></Icon>
            <Text size="md" color="light" css={{ width: '90%', '@md': { width: '50%' } }} align="center">
              {lang.t('note.empty.tips')}
            </Text>
          </FlexBox>
        </>
      )}
    </Wrapper>
  );
});
