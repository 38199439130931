import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import NavigatorBar from '../../components/NavigatorBar';
import { navStyles, mainStyles } from '../../styles/common';
import { useStore } from '../../store';
import God from '../../God';
import Loading from '../../components/Loading';
import Config from '../../Config';
import Alert from '../../components/Alert';
import ModalView from '../../components/ModalView';
import 'react-toastify/dist/ReactToastify.css';
import LiquidtyView from '../../components/LiquidtyView';
import TransactionObject from '../../TransactionObject';
import Footer from '../../components/Footer';
import { css } from '../../modules/index';
import ReactTooltip from 'react-tooltip';

export const VotingPage = observer(() => {
  const { base, iotex: pageStore, lang } = useStore();
  const [attachView, setAttachView] = useState(null);

  useEffect(() => {
    God.init();
    base.init();
    lang.init();
    pageStore.init();
  }, []);

  const onConnect = account => {
    pageStore.setConnectionStatus(true);
    console.log('onConnect', account)
    // FOR TESTING...
    // God.getRewardAddedLogs()
    // showDepositResult("10000000000000000000000")
    // return;

    pageStore.setModalViewShow(false);
    setAttachView(null);
  };

  const onNotConnect = () => {
    pageStore.setConnectionStatus(false);
    pageStore.setModalViewShow(false);
    setAttachView(
      <ModalView>
        <Loading
          action={() => {
            God.connectIoPay(account => {
              if (account) {
                return onConnect(account);
              }
            });
          }}
          cancelable={true}
          cancel={() => {
            pageStore.setModalViewShow(false);
            pageStore.setConnectionStatus(false);
          }}
          text={
            <div>
              <p>{lang.t('connect.no.iopay')}</p>
              <p>
                {lang.t('connect.no.iopay.content')}&nbsp;
                <a href="https://iopay.iotex.io" target="_blank">
                  https://iopay.iotex.io
                </a>
              </p>
            </div>
          }
        />
      </ModalView>
    );
    pageStore.setModalViewShow(true);
  };

  const onStake = async (amount, LPTokenAddress, LPTokenABI, { allowance }) => {
    if (Number(allowance) >= Number(amount)) {
      showFinalStakView({ amount });
    } else {
      pageStore.setModalViewShow(true);
      setAttachView(
        <ModalView>
          <Loading
            action={async () => {
              God.preStake(Config.Aeolus.address, amount, LPTokenAddress, LPTokenABI, txid => {
                checkStakeReady(txid, amount);
              });
            }}
            text={lang.t('Loading.text6')}
            cancelable={true}
            cancel={() => {
              pageStore.setModalViewShow(false);
              setAttachView(null);
            }}
          />
        </ModalView>
      );
    }
  };

  const connectIoPay = () => {
    pageStore.setModalViewShow(true);
    setAttachView(
      <ModalView>
        <Loading
          action={() => {
            God.connectIoPay(account => {
              if (account) {
                return onConnect(account);
              } else {
                console.log('unconnect');
              }
              return onNotConnect();
            });
          }}
          text={lang.t('iopay.connect')}
        />
      </ModalView>
    );
  };

  const checkStakeReady = (actionHash, amount) => {
    const tx = new TransactionObject(0, 0, actionHash, 0, 0, null, null, null);
    tx.getReceipt(() => {
      tx.getActions(result => {
        if (!result) {
          return showSimpleAlert(lang.t('approval.transaction.error'));
        }
        showFinalStakView({ amount });
      });
    });
  };

  const showFinalStakView = ({ amount }) => {
    pageStore.setModalViewShow(true);
    setAttachView(
      <ModalView>
        <Loading
          text={lang.t('Loading.text7')}
          cancelable={true}
          cancel={() => {
            pageStore.setModalViewShow(false);
            setAttachView(null);
          }}
          nextable={true}
          next={async () => {
            const abi = await God.asyncGetABI(Config.Aeolus.abi);
            God.stake(
              Config.Aeolus.address,
              abi,
              {
                _pid: 0,
                _amount: amount
              },
              result => {
                if (result) {
                  checkStakeResult(result);
                }
              }
            );
          }}
        />
      </ModalView>
    );
  };

  const showSimpleAlert = text => {
    pageStore.setModalViewShow(true);
    setAttachView(
      <ModalView>
        <Alert
          onClose={() => {
            pageStore.setModalViewShow(false);
            setAttachView(null);
          }}
          text={text}
        />
      </ModalView>
    );
  };

  const checkStakeResult = actionHash => {
    const tx = new TransactionObject(0, 0, actionHash, 0, 0, null, null, null);
    tx.getReceipt(receiptResult => {
      if (receiptResult === -1) {
        return showSimpleAlert(lang.t('staking.failed'));
      }

      tx.getActions(result => {
        if (result) {
          navigateToLiquidty();
        }
      });
    });
  };

  const onUnstake = txid => {
    pageStore.setModalViewShow(false);
    setAttachView(null);
  };

  const navigateToLiquidty = () => {
    pageStore.setModalViewShow(true);

    setAttachView(
      <ModalView>
        <LiquidtyView
          stake={onStake}
          unstake={onUnstake}
          onClickCloseButton={() => {
            pageStore.setModalViewShow(false);
            setAttachView(null);
          }}
        />
      </ModalView>
    );
  };

  return (
    <>
      <div className="App">
        <div className={mainStyles.mainView.className}>
          <div className={navStyles.title.className}>
            <NavigatorBar
              navigateToLiquidty={navigateToLiquidty}
              isConnect={pageStore.isConnect.className}
              onClick={!pageStore.isConnect ? connectIoPay : pageStore.logout}
            />
          </div>
          <div className={mainStyles.mainBlock.className}>
            <div className={styles.votingContainer.className}>
              <div className="title">{lang.t("dao.governance")}</div>
              <div className="voting-content">
                {/* title */}
                <div className="voting-table-title">
                  <span>{lang.t("proposals")}</span>
                  <button className="new-proposal" data-tip="new-proposal" data-for='new-proposal'>{lang.t('new.proposal')}</button>

                  <ReactTooltip place="bottom" id="new-proposal" type="warning" effect="solid" backgroundColor="#45bcb8">
                    <div style={{ textAlign: 'center', fontWeight: 'bold', width: '200px' }}>
                      {lang.t('new.proposal.tips')}
                    </div>
                  </ReactTooltip>
                </div>

                {/* list */}
                <article className={styles.proppsalContainer.className}>
                  <div className={styles.proppsalItem.className}>
                    <div className="proppsalItem_l">
                      <div className="index">1</div>
                      <div>
                        <p className="tbd">{lang.t("tbd")}</p>
                        <p className="date">
                          {lang.t("start.date")}：<span>{lang.t("tbd")}</span>
                          {lang.t("end.date")}：<span>{lang.t("tbd")}</span>
                        </p>
                        <div className="inactive">{lang.t("inactive")}</div>
                      </div>
                    </div>
                    <div className="proppsalItem_r">
                      <div className="vote-item">
                        <img src="/images/check_circle.png" alt="" />
                        <span className="vote">{lang.t('vote.for')}</span>
                        <span className="amount">0.0000 {lang.t('cyc')}</span>
                      </div>
                      <div className="vote-item">
                        <img src="/images/add_circle.png" alt="" />
                        <span className="vote">{lang.t("vote.against")}</span>
                        <span className="amount">0.0000 {lang.t('cyc')}</span>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  )
})

const styles = {
  votingContainer: css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    ".title": {
      fontSize: '1.875rem',
      textAlign: 'center',
      fontWeight: 'bold',
      color: '#38DCD5',
      paddingBottom: '23px',
      borderBottom: '1px solid #494949'
    },
    ".voting-content": {
      flex: 1,
      marginTop: '1.8rem',
      ".voting-table-title": {
        flexBetweenCenter: 'column',
        marginBottom: '1.8rem',
        '@md': {
          flexBetweenCenter: 'row',
        },
        span: {
          fontSize: '1.125rem',
          fontWeight: 'bold',
          color: '#fff',
          marginBottom: '1rem',
          '@md': {
            marginBottom: 0
          }
        },
        ".new-proposal": {
          backgroundColor: 'transparent',
          outline: 'none',
          border: '1px solid #45BCB8',
          fontSize: '0.875rem',
        }
      }
    }
  }),
  proppsalContainer: css({
    marginBottom: '4rem',
    '@md': {
      marginBottom: 0
    }
  }),
  proppsalItem: css({
    border: '1px solid #45BCB8',
    backgroundColor: 'rgba(11, 25, 28, 0.7)',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: '0 15px',
    '@md': {
      padding: '10px 0',
      flexDirection: 'row'
    },
    ".proppsalItem_l": {
      display: 'flex',
      flexDirection: 'column',
      '@md': {
        flexDirection: 'row'
      },
      ".index": {
        fontSize: '1.125rem',
        color: '#Ffff',
        fontWeight: 'bold',
        display: 'flex',
        py: '0.5rem',
        borderBottom: '1px solid #848484',
        marginBottom: '1.5rem',
        '@md': {
          px: '1.8rem',
          marginRight: '1rem',
          alignItems: 'center',
          borderRight: '1px solid #848484',
          borderBottom: 0,
          marginBottom: 0
        }
      },
      ".tbd": {
        fontSize: '1.125rem',
        fontWeight: 'bold',
        marginBottom: '4px'
      },
      ".date": {
        marginBottom: '12px',
        fontSize: '0.875rem',
        color: '#848484',
        span: {
          color: '#45BCB8',
          "&:first-child": {
            marginRight: '2.5rem'
          }
        }
      },
      ".inactive": {
        padding: '4px 6px',
        backgroundColor: '#7EAEDF',
        fontSize: '0.875rem',
        color: '#000',
        width: 'max-content'
      }
    },
    ".proppsalItem_r": {
      borderTop: '1px solid #45BCB8',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: '1.5rem',
      py: '1rem',
      '@md': {
        borderLeft: '1px solid #45BCB8',
        borderTop: 0,
        marginTop: 0,
        paddingLeft: '0.8rem',
        paddingRight: '1.75rem',
        py: '0'
      },
      ".vote-item": {
        marginBottom: '0.8rem',
        "&:last-child": {
          marginBottom: 0
        },
        img: {
          width: '1.5rem',
          height: '1.5rem',
          marginRight: '0.6rem'
        },
        ".vote": {
          fontSize: '0.875rem',
          color: '#38DCD5',
          width: '110px',
          display: 'inline-block'
        },
        ".amount": {
          marginLeft: '2.8rem'
        }
      }
    }
  })
}